//@ts-nocheck
import { useTranslation } from 'react-i18next';
import AllDailyRewardItem from '../AllDailyRewardItem/AllDailyRewardItem';
import './QuestOffcanvasAllDailyReward.scss'

import Countdown from 'react-countdown';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserDailyRewards } from '../../api/userDailyReward';
import store from '../../redux/store';
import { incrementUserQuestCount } from '../../redux/userQuestCountSlice/userQuestCountSlice';

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        return;
    } else {
        return (<><span>{(hours < 10 ? '0' : '') + hours}:{(minutes < 10 ? '0' : '') + minutes}:{(seconds < 10 ? '0' : '') + seconds}</span></>);
    }
};
const CountdownWrapper = ({ timeAwait }) => {
    return <Countdown date={Date.now() + timeAwait} renderer={renderer} onComplete={() => store.dispatch(incrementUserQuestCount())} />;
};
const MemoCountdown = React.memo(CountdownWrapper);

function QuestOffcanvasAllDailyReward({ data, isTimerComplete, currentDay }) {

    const { t } = useTranslation();

    const userQuestCount = useSelector((state) => state.userQuestCount.count)
    useEffect(() => {
        const data = getUserDailyRewards().then((data) => {
            if (data.data.timeAwait === 0) {
                isTimerComplete = true
            }
        });
    }, [userQuestCount])

    return (
        <div className="quest-offcanvas-all-daily-reward overflow-auto offcanvas offcanvas-bottom d-flex flex-column align-items-center" tabIndex="-1" id="offcanvasBottomAllDailyRewards" aria-labelledby="offcanvasBottomAllDailyRewardsLabel">

            <div className="position-relative w-100">
                <svg className='position-absolute top-0 end-0' width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas" aria-label="Закрыть" style={{ cursor: 'pointer', zIndex: 2 }}>
                    <path d="M1.03613 1.00055L11.0361 11" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 10.9987L10.9991 1" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <div className='quest-offcanvas-all-daily-reward-title-container d-flex align-items-center'>
                <div className='quest-offcanvas-all-daily-image-container'>
                    <img src='/img/daily/all-daily-box.png' />
                </div>
                <div className=''>
                    <div className='quest-offcanvas-all-daily-reward-title'>
                        {t("questOffcanvasAllDailyReward.title")}
                    </div>
                    <div className='quest-offcanvas-all-daily-text-timer'>
                        {data.data.timeAwait > 0 && !isTimerComplete &&
                            <>
                                <span className='me-1'>{t("questOffcanvasAllDailyReward.timer")}</span>
                                <MemoCountdown timeAwait={data.data.timeAwait}/>
                            </>
                            // <MemoCountdown date={Date.now() + data.data.timeAwait} renderer={renderer}>
                        }
                        {isTimerComplete &&
                            <span>{t("questOffcanvasAllDailyReward.TakeReward")}</span>
                        }
                    </div>
                </div>
            </div>

            <div className='d-flex flex-wrap justify-content-center' style={{ gap: "10px" }}>
                {data.data.allUserDailyRewards.map((AllDailyRewardItemEl, index) => {
                    return (
                        <AllDailyRewardItem
                            key={AllDailyRewardItemEl.day}
                            status={AllDailyRewardItemEl.status}
                            day={AllDailyRewardItemEl.day}
                            amount={AllDailyRewardItemEl.amount}
                            pathImage={AllDailyRewardItemEl.pathImage}
                            rewardType={AllDailyRewardItemEl.rewardType}
                            name={AllDailyRewardItemEl.name}
                            nameEn={AllDailyRewardItemEl.nameEn}
                            isTimerComplete={isTimerComplete}
                            currentDay={currentDay}
                            rate={AllDailyRewardItemEl.rate}
                        />
                    )
                })
                }
            </div>
        </div>
    )
}

export default QuestOffcanvasAllDailyReward