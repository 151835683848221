import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function upsertByUserId(address: string) {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-wallet/upsert-by-user-id', 
        {
            address: address,
        },
        axiosConfig
    )    
    return data
}

export async function updateStatusByUserId(status: string) {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-wallet/update-status-by-user-id', 
        {
            status: status,           
        },
        axiosConfig
    )    
    return data
}