//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

const NAME = "userOfflineReward"

export const userOfflineRewardSlice = createSlice({
    name: NAME,
    initialState: {
        timeAbsenceSec: 0,
    },
    reducers: {
        setUserOfflineReward: (state, action) => {     
            state.timeAbsenceSec = action.payload.timeAbsenceSec             
        },
    },
})

export const { setUserOfflineReward } = userOfflineRewardSlice.actions

export default userOfflineRewardSlice.reducer