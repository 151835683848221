//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeInvoiceWithdrawal.scss'
import { useEffect, useState } from 'react'
import { getByUserId } from '../../../../../api/userTon'
import DataFetchLoader from '../../../../DataFetchLoader/DataFetchLoader'
import { useMutation, useQueryClient } from 'react-query'
import { create } from '../../../../../api/userTonInvoice'
import ToastError from '../../../../Toasts/ToastError/ToastError'

export default function TonPromoSectionsExchangeInvoiceWithdrawal() {

    const { t, i18n } = useTranslation()

    const [userTonInput, setUserTonInput] = useState("")

    const [totalOnClickLoading, setTotalOnClickLoading] = useState(false)
    const [withdrawalOnClickLoading, setWithdrawalOnClickLoading] = useState(false)

    const totalOnClick = async () => {
        setTotalOnClickLoading(true)
        const data = await getByUserId()
        setUserTonInput(data.userTon.count)
        setTotalOnClickLoading(false)
    }

    const totalOnChange = async (e) => {
        setUserTonInput(Number(e.target.value).toString())
    }

    const queryClient = useQueryClient()
    const withdrawalMutation = useMutation((count: number) => create(count), {
        onSuccess: (data) => {

            if(data.ok === false) {

                if(data.ErrorCode === "Count0") {
                    ToastError(t("tonPromoSectionsExchangeInvoiceWithdrawal.count0"), true)
                }

                if(data.ErrorCode === "Count1") {
                    ToastError(t("tonPromoSectionsExchangeInvoiceWithdrawal.count1"), true)
                }

                if(data.ErrorCode === "CountInsufficient") {
                    ToastError(t("tonPromoSectionsExchangeInvoiceWithdrawal.countInsufficient"), true)
                }

                if(data.ErrorCode === "UserWalletNull") {
                    ToastError(t("tonPromoSectionsExchangeInvoiceWithdrawal.userWalletNull"), true)
                }

                setWithdrawalOnClickLoading(false)
                return
            }
            
            queryClient.invalidateQueries(['userTon']).then(() => {              
                queryClient.invalidateQueries(['userTonInvoiceHistory']).then(() => {          
                    setWithdrawalOnClickLoading(false)
                    setUserTonInput("")
                })
            })
        },
        onError: () => {
            ToastError("Ой! Что-то пошло не так...")
        }
    })
    const withdrawalOnClick = async () => {

        if(withdrawalOnClickLoading)
            return

        setWithdrawalOnClickLoading(true)

        withdrawalMutation.mutate(userTonInput)
    }

    return (
        <div className='ton-promo-sections-exchange-invoice-withdrawal w-100'>
            <div className='ton-promo-sections-exchange-invoice-form d-flex align-items-center w-100'>
                <div className='d-flex w-100 align-items-center'>
                    <img src='/img/ton/ton-icon.png' width={17.37} height={22} style={{ minWidth: "17.37px" }} />
                    <input className='ton-promo-sections-exchange-invoice-form-input w-100' type="number" value={userTonInput} onChange={e => totalOnChange(e)}  step="any" pattern="\d*"/>
                </div>
                <button onClick={totalOnClick} className='ton-promo-sections-exchange-invoice-form-btn'>
                    {!totalOnClickLoading &&
                        t("tonPromoSectionsExchangeInvoiceWithdrawal.btn")
                    }
                    {totalOnClickLoading &&
                        <DataFetchLoader height={15} width={15} color={"#B28E4C"} />
                    }
                </button>
            </div>
            <button onClick={withdrawalOnClick} className='ton-promo-sections-exchange-invoice-withdrawal-btn w-100'>

                {!withdrawalOnClickLoading &&
                    t("tonPromoSectionsExchangeInvoiceWithdrawal.withdrawal")
                }
                {withdrawalOnClickLoading &&
                    <DataFetchLoader height={20} width={20} color={"#171027"} />
                }
            </button>
            <div className='ton-promo-sections-exchange-invoice-withdrawal-min w-100'>
                {t("tonPromoSectionsExchangeInvoiceWithdrawal.min")}
            </div>
        </div>
    )
}