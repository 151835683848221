//@ts-nocheck
import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeInvoiceTons.scss'
import { useQuery } from 'react-query';
import { getByUserId } from '../../../../../api/userTon';
import DataFetchLoader from '../../../../DataFetchLoader/DataFetchLoader';

export default function TonPromoSectionsExchangeInvoiceTons() {

    const { t, i18n } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["userTon"],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='ton-promo-sections-exchange-invoice-tons w-100 d-flex'>
            <div style={{marginRight: '20px'}}>
                {t("tonPromoSectionsExchangeInvoiceTons.title")}
            </div>
            <div>
                {isSuccess &&
                    <span className="ton-promo-balance-count">
                        {data.userTon.count}
                    </span>
                }
                {isLoading &&
                    <DataFetchLoader width={20} height={20} color={"#B28E4C"} />
                }
            </div>
        </div>
    )
}