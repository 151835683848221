//@ts-nocheck

import './MinesContainerAvailable.scss';
import { useTranslation } from 'react-i18next'
import MinesModalAdd from "../MinesModalAdd/MinesModalAdd";
import { useQuery } from "react-query";
import { getUserHeroes } from "../../../api/userHero";
import { useState } from "react";
import { MemoCountdown } from '../../MemoCountdown/MemoCountdown';

export default function MinesContainerAvailable({ mine }) {

    const { t } = useTranslation()
    const { i18n } = useTranslation()

    return (
        <>
            <div className='hero-mines-container' style={{background: 'rgba(0, 0, 0, 0.5)'}}>
                <div className='text-white hero-mines-label'>
                    {i18n.language === 'en' &&
                        (mine.mine.nameEn)
                    }
                    {i18n.language === 'ru' &&
                        (mine.mine.nameRu)
                    }
                </div>
                <div className='w-100 d-flex justify-content-between hero-mines-reward-container align-items-center' style={{background: 'rgba(0, 0, 0, 0.5)'}}>
                    <div className='d-flex align-items-center gap-1'>
                        <div className='hero-mines-gray-text'>
                            {t("mines.reward")} <span>+{mine.mine.reward}%</span>
                        </div>
                        {/* <div> */}
                            <img src='./img/loot/crystal-icon.png' width={'7.89px'} />
                        {/* </div> */}
                    </div>

                    <div className='hero-mines-gray-text'>
                        {t("mines.time")} {mine.mine.hours < 9 ? `0${mine.mine.hours}:00:00` : `${mine.mine.hours}:00:00`}
                    </div>
                </div>

                <div className='d-flex justify-content-between align-items-center hero-mines-add-container'>
                    <div className='hero-mines-btn-container position-relative' data-bs-toggle="modal" data-bs-target={"#minesModal" + mine.mine.id}>
                        <svg width="82" height="26" viewBox="0 0 82 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M81.2105 12.9115L69.8071 24.821H18.0716L4.98096 12.9115L18.0716 1H69.8071L81.2105 12.9115Z"
                                stroke="#B0B0B0" strokeMiterlimit="10" />
                            <path d="M77.2296 13.0905L65.8261 25H14.0906L1 13.0905L14.0906 1.17896H65.8261L77.2296 13.0905Z"
                                stroke="#B0B0B0" strokeMiterlimit="10" />
                        </svg>
                        <div className='position-absolute w-100 top-0 bottom-0 my-auto d-flex justify-content-center align-items-center hero-mines-gray-text'>
                            {t("mines.available")}
                        </div>
                    </div>

                    <div className='d-flex justify-content-between hero-mines-imgs' style={{gap:'6px', overflowX: 'auto'}}>
                        <div className='hero-mines-add-button d-flex justify-content-center align-items-center'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                    fill="#B0B0B0" />
                            </svg>
                        </div>

                        <div className='hero-mines-add-button d-flex justify-content-center align-items-center'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                    fill="#B0B0B0" />
                            </svg>
                        </div>

                        <div className='hero-mines-add-button d-flex justify-content-center align-items-center'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                    fill="#B0B0B0" />
                            </svg>
                        </div>

                        <div className='hero-mines-add-button d-flex justify-content-center align-items-center'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M11.3487 5.99996C11.3487 6.16737 11.2822 6.31883 11.1704 6.42782C11.0613 6.5394 10.9096 6.60583 10.7393 6.60583H6.61704V10.7248C6.61704 11.0596 6.34298 11.3333 6.00767 11.3333C5.84001 11.3333 5.68829 11.2642 5.57655 11.1552C5.46739 11.0436 5.39829 10.8922 5.39829 10.7247V6.60583H1.27603C0.938047 6.60583 0.666656 6.33477 0.666656 5.99996C0.666656 5.83255 0.733169 5.67841 0.844913 5.56942C0.95407 5.46043 1.1057 5.39133 1.27603 5.39133H5.39821V1.2725C5.39821 0.937682 5.67227 0.666626 6.00758 0.666626C6.17524 0.666626 6.32696 0.735725 6.4387 0.844632C6.54786 0.953622 6.61696 1.10509 6.61696 1.2725V5.39142H10.7391C11.0772 5.39142 11.3487 5.66247 11.3487 5.99996Z"
                                    fill="#B0B0B0" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <MinesModalAdd mineId={mine.mine.id} reward={mine.mine.reward} />
        </>
    )
}