import './TonPromoSectionsNFTs.scss'
import TonPromoSectionsNFTsItems from './TonPromoSectionsNFTsItems/TonPromoSectionsNFTsItems'
import TonPromoSectionsNFTsSearch from './TonPromoSectionsNFTsSearch/TonPromoSectionsNFTsSearch'
import TonPromoSectionsNFTsUserCollection from './TonPromoSectionsNFTsUserCollection/TonPromoSectionsNFTsUserCollection'

export default function TonPromoSectionsNFTs() {
  return (
    <div className='ton-promo-sections-nfts h-100'>

        <div className='d-flex' style={{paddingBottom: '12px'}}>
            <TonPromoSectionsNFTsSearch/>
            <TonPromoSectionsNFTsUserCollection/>   
        </div>

        <TonPromoSectionsNFTsItems/>

    </div>
  )
}
