import ApiErrorIcon from '../ApiError/ApiErrorIcon/ApiErrorIcon'
import './ApiErrorHash.scss'
import ApiErrorHashIcon from './ApiErrorHashIcon/ApiErrorHashIcon'

export default function ApiErrorHash() {
    return (
        <div className="api-error-hash w-100 h-100 d-flex justify-content-center align-items-center flex-column position-fixed" style={{ zIndex: 99999999999999 }}>
            <ApiErrorHashIcon/>
            Извините, но игра уже запущена на другом устройстве. Пожалуйста, выйдите из системы с другого устройства и обновите текущий сеанс, прежде чем продолжить.
        </div>
    )
}
