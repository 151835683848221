//@ts-nocheck
import './Coin.scss'

import { CoinClick } from '../../components/CoinClick/CoinClick';
import CoinEnergyBar from '../../components/CoinEnergyBar/CoinEnergyBar';
import CoinUserStats from '../../components/CoinUserStats/CoinUserStats';
import CoinUserGoldCount from '../../components/CoinUserGoldCount/CoinUserGoldCount';

function Coin() {
    return (<>
            <div id='background-coin' className='h-100 w-100 overflow-hidden coin-background position-absolute'></div>
            <div id='coin-page' className='h-100 main-container position-relative'>
                <CoinUserStats/>
                <div id='gold-count'>
                    <CoinUserGoldCount/>
                </div>
                <CoinClick/>
                <div className='position-absolute health_bar d-flex justify-content-center w-100 ' id='energy-bar'>
                    <CoinEnergyBar id='energy-bar'/>
                </div>
            </div>
        </>

    )
}

export default Coin;