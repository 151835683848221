//@ts-nocheck

import { useEffect, useState } from 'react'
import './TonPromoSections.scss'
import { useTranslation } from 'react-i18next';
import ToastInfo from '../Toasts/ToastInfo/ToastInfo';
import TonPromoSectionsFrens from './TonPromoSectionsFrens/TonPromoSectionsFrens';
import TonPromoSectionsNFTs from './TonPromoSectionsNFTs/TonPromoSectionsNFTs';
import TonPromoSectionsExchange from './TonPromoSectionsExchange/TonPromoSectionsExchange';

export default function TonPromoSections() {

    const { t } = useTranslation()

    const [activeNavbarItem, setActiveNavbarItem] = useState('')
    useEffect(() => {
        setActiveNavbarItem('exchange')
    }, [])

    return (
        <div className='ton-promo-sections h-100 d-flex flex-column'>
            <div className='d-block'>
                <div className='ton-promo-sections-navbar d-flex justify-content-between'>
                    <div onClick={() => setActiveNavbarItem('exchange')} className={`ton-promo-sections-navbar-item ${activeNavbarItem === 'exchange' ? 'ton-promo-sections-navbar-item-active' : ''} d-flex align-items-center justify-content-center`}>
                        {t("tonPromoSections.exchange")}
                    </div>

                    <div onClick={() => setActiveNavbarItem('frens')} className={`ton-promo-sections-navbar-item ${activeNavbarItem === 'frens' ? 'ton-promo-sections-navbar-item-active' : ''} d-flex align-items-center justify-content-center`}>
                        {t("tonPromoSections.friends")}
                    </div>

                    <div onClick={() => setActiveNavbarItem('nfts')} className={`ton-promo-sections-navbar-item ${activeNavbarItem === 'nfts' ? 'ton-promo-sections-navbar-item-active' : ''} d-flex align-items-center justify-content-center`}>
                        {t("tonPromoSections.nfts")}
                    </div>
                </div>
            </div>

            {activeNavbarItem === 'exchange' &&
                <TonPromoSectionsExchange />
            }

            {activeNavbarItem === 'frens' &&
                <TonPromoSectionsFrens />
            }

            {activeNavbarItem === 'nfts' &&
                <TonPromoSectionsNFTs />
            }
            
        </div>
    )
}