//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeConditions.scss'
import { useTonAddress, useTonConnectModal, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useEffect } from 'react';
import { upsertByUserId } from '../../../../api/userWallet';
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader';
import TonPromoSectionsExchangeInvoice from '../TonPromoSectionsExchangeInvoice/TonPromoSectionsExchangeInvoice';

export default function TonPromoSectionsExchangeConditions({ data, isLoading, isSuccess }) {

    const { t, i18n } = useTranslation()

    const [tonConnectUI, setOptions] = useTonConnectUI();
    tonConnectUI.uiOptions = {
        language: i18n.language,
        uiPreferences: {
            theme: "DARK"
        }
    };
    const { state } = useTonConnectModal();
    const wallet = useTonWallet();

    const userFriendlyAddress = useTonAddress();
    useEffect(() => {
        if (userFriendlyAddress === "") return
        if (userFriendlyAddress === undefined) return

        upsertByUserId(userFriendlyAddress)

    }, [userFriendlyAddress])

    useEffect(() => {
        tonConnectUI.uiOptions = {
            actionsConfiguration: {
                returnStrategy: "back",
            },
        };
    }, []);

    const connectWalletBtn = async () => {
        if (wallet) {
            return
        }

        tonConnectUI.openModal()
    }

    return (
        <div className='ton-promo-sections-exchange-conditions'>

            {(isSuccess && (!data.joinChannel || !wallet)) &&
                <div className='ton-promo-sections-exchange-conditions-title' style={{ maxWidth: i18n.language === 'en' ? '185px' : '280px' }}>
                    {t("tonPromoSectionsExchangeConditions.title")}
                </div>
            }

            {(isSuccess && (!data.joinChannel || !wallet)) &&
                <>
                    <div className={`ton-promo-sections-exchange-conditions-item ${data.joinChannel ? 'ton-promo-sections-exchange-conditions-item-complete' : ''} w-100 d-flex align-items-center justify-content-between`}>

                        <div className='ton-promo-sections-exchange-conditions-item-title'>
                            {t("tonPromoSectionsExchangeConditions.itemTitleChannel")}
                        </div>

                        <div>
                            {!data.joinChannel &&
                                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L9.94984 10L1 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            {data.joinChannel &&
                                <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 11.9527L7.4634 18.4936L24.3031 1" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.6377 16.2546L13.8635 18.5046L30.7032 1" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.40039 11.9526L9.5707 14.1531" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                        </div>

                    </div>

                    <div onClick={connectWalletBtn} className={`ton-promo-sections-exchange-conditions-item ${wallet ? 'ton-promo-sections-exchange-conditions-item-complete' : ''} w-100 d-flex align-items-center justify-content-between`}>

                        <div className='ton-promo-sections-exchange-conditions-item-title'>
                            {t("tonPromoSectionsExchangeConditions.itemTitleWallet")}
                        </div>

                        <div>
                            {(state.status === "closed" && !wallet) &&
                                <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L9.94984 10L1 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            {(state.status === "closed" && wallet) &&
                                <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 11.9527L7.4634 18.4936L24.3031 1" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.6377 16.2546L13.8635 18.5046L30.7032 1" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.40039 11.9526L9.5707 14.1531" stroke="#218D45" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            {state.status === "opened" &&
                                <DataFetchLoader width={20} height={20} color={'#B28E4C'} />
                            }
                        </div>

                    </div>
                </>
            }

            {(isSuccess && (data.joinChannel && wallet)) &&
                <TonPromoSectionsExchangeInvoice />
            }

        </div>
    )
}