//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeAccess.scss'
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader'
import { useTonWallet } from '@tonconnect/ui-react';

export default function TonPromoSectionsExchangeAccess({ data, isLoading, isSuccess }) {

    const { t } = useTranslation()
    const wallet = useTonWallet()

    return (
        <div className='ton-promo-sections-exchange-access d-flex justify-content-between'>

            <div>
                <div className='ton-promo-sections-exchange-title'>
                    {t("tonPromoSectionsExchangeAccess.title")}
                </div>
                <div className='ton-promo-sections-exchange-desc'>
                    {t("tonPromoSectionsExchangeAccess.desc")}
                </div>
            </div>

            {isSuccess &&
                <>
                    {(data.joinChannel && wallet) &&
                        <div className='ton-promo-sections-exchange-allowed d-flex align-items-center'>
                            {t("tonPromoSectionsExchangeAccess.allowed")}
                        </div>
                    }

                    {(!data.joinChannel || !wallet) &&
                        <div className='ton-promo-sections-exchange-not-allowed d-flex align-items-center'>
                            {t("tonPromoSectionsExchangeAccess.notAllowed")}
                        </div>
                    }
                </>
            }

            {isLoading &&
                <div>
                    <DataFetchLoader width={21} height={21} color={'#B28E4C'} />
                </div>    
            }

        </div>
    )
}