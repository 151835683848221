//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsNFTsUserCollection.scss'
import ToastInfo from '../../../Toasts/ToastInfo/ToastInfo'

export default function TonPromoSectionsNFTsUserCollection() {
    const { t } = useTranslation()

    return (
        <div onClick={() => ToastInfo(t("tonPromoSectionsNFTsUserCollection.toastInfo"), true)} className='ton-promo-sections-nfts-user-collection d-flex justify-content-center align-items-center'>
            {t("tonPromoSectionsNFTsUserCollection.title")}
        </div>
    )
}