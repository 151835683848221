//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'
import { initCloudStorage } from '@telegram-apps/sdk'
import { decrement, increment } from '../../api/userEnergy'

const NAME = "userEnergyCount"

const cloudStorage = initCloudStorage()

const userEnergyCountMax = 100 
const lastUserEnergyCountStorage = await cloudStorage.get("lastUserEnergyCount")

export const userEnergyCountSlice = createSlice({
    name: NAME,
    initialState: {
        color: '#B0B0B0',
        countMax: userEnergyCountMax,
        count: 0
    },
    reducers: {
        setColor: (state, action) => {
            state.color = action.payload;
        },
        setUserEnergyCount: (state, action) => {                            
            state.count = action.payload
            console.log(state.count);
        },
        incrementUserEnergyCount: (state, action) => {                
            if(state.count >= state.countMax)
                return
            state.count++ 

            increment()

        },
        decrementUserEnergyCount: (state, action) => {  
            if(state.count - 1 === -1)
                return   
            state.count--  

            decrement()
        },
    },
})

export const { setColor, setUserEnergyCount, incrementUserEnergyCount, decrementUserEnergyCount } = userEnergyCountSlice.actions

export default userEnergyCountSlice.reducer