//@ts-nocheck
import './ShopChestInfoModal.scss';
import { useTranslation } from "react-i18next";

export default function ShopChestInfoModal({ chestname, chestHeroes, id }) {

    const { t } = useTranslation();
    return (
        <>
            <div className="modal fade" id={"chestModal" + id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content chest-info-modal-container w-100">
                        <div className='text-center text-white chest-info-modal-label'>
                            {t("chestModal.content")} {chestname}
                        </div>

                        <div className='chest-info-modal-fragments-container'>
                            <div className='chest-info-modal-text'>{t('chestModal.guaranteed')}</div>
                            <div className='d-flex flex-wrap chest-info-modal-image-container align-items-center justify-content-center'>
                                {chestHeroes.length > 0 ? chestHeroes.map((hero) => {
                                    if (hero.countMin !== hero.countMax) {
                                        return (<>
                                            <div className='chest-info-modal-image position-relative'>
                                                <div style={{width: '60px'}} className='d-flex justify-content-center'>
                                                    <img src={`img/heroes/${hero.hero.pathImageFragment}.png`} height='55.5px'
                                                        width='auto' />
                                                </div>
                                                <div className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                                    {hero.countMin}-{hero.countMax}
                                                </div>
                                            </div>
                                        </>)
                                    }
                                }) : null}
                            </div>
                        </div>

                        <div className='chest-info-modal-heroes-container'>
                            <div className='chest-info-modal-text'>{t('chestModal.chance')}</div>
                            <div
                                className='d-flex flex-wrap chest-info-modal-image-container align-items-center justify-content-center'>
                                {chestHeroes.length > 0 ? chestHeroes.map((hero) => {
                                    if (hero.countMin === hero.countMax) {
                                        return (<>
                                            <div className='chest-info-modal-image position-relative'>
                                                <div>
                                                    <img src={`img/heroes/${hero.hero.pathImage}.png`} height='55.5px'
                                                        width='55.5px' />
                                                </div>
                                                <div className='chest-info-modal-hero-name text-white d-flex justify-content-center align-items-center position-absolute'>
                                                    {localStorage.getItem('i18nLanguage') === 'en' ? hero.hero.nameEn : hero.hero.nameRu}
                                                </div>
                                            </div>
                                        </>)
                                    }
                                }) : null}
                            </div>
                        </div>

                        <div
                            className='chest-info-modal-back-btn position-relative text-white d-flex justify-content-center align-items-center'
                            type="button" data-bs-dismiss="modal">
                            <svg width="137" height="42" viewBox="0 0 137 42" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M135.684 20.8525L116.678 40.7017H30.4526L8.63489 20.8525L30.4526 1H116.678L135.684 20.8525Z"
                                    fill="#171027" fillOpacity="0.3" stroke="#B28E4C" strokeMiterlimit="10" />
                                <path
                                    d="M129.049 21.1508L110.044 41H23.8177L2 21.1508L23.8177 1.29822H110.044L129.049 21.1508Z"
                                    stroke="#B28E4C" strokeWidth="2" strokeMiterlimit="10" />
                            </svg>
                            <div className='position-absolute d-flex justify-content-center align-items-center lh-1'>
                                {t("chestModal.back")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}