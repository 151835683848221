//@ts-nocheck

import { useQuery } from "react-query";
import "./TonPromoBalance.scss";
import { useTranslation } from "react-i18next";
import { getByUserId } from "../../api/userTon";
import DataFetchLoader from "../DataFetchLoader/DataFetchLoader";
import DataFetchError from "../DataFetchError/DataFetchError";

export default function TonPromoBalance() {
    const { t } = useTranslation();

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["userTon"],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    );

    return (
        <div className="ton-promo-balance">
            <div className="ton-promo-balance-title">
                {t("tonPromoBalance.title")}
            </div>
            <div className="d-flex align-items-center">
                <img
                    src="/img/ton/ton-icon.png"
                    width={17.4}
                    height={22}
                    style={{ marginRight: "5px" }}
                />

                {isSuccess && (
                    <>
                        {data.userTon !== null && (
                            <span className="ton-promo-balance-count">
                                {data.userTon.count}
                            </span>
                        )}
                        {data.userTon === null && (
                            <span className="ton-promo-balance-count" style={{color: '#B0B0B0', fontSize: '15px'}}>
                                {t("tonPromoBalance.connectWallet")}
                            </span>
                        )}
                    </>
                )}

                {isLoading && (
                    <div>
                        <DataFetchLoader width={13} height={13} color={"#B28E4C"} />
                    </div>
                )}

                {isError && (
                    <DataFetchError text={t("tonPromoBalance.dataFetchError")} />
                )}
            </div>
        </div>
    );
}
