//@ts-nocheck
import React, { Suspense } from "react";

import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { isOpenTelegram } from "./utils/telegram";
import QrCode from "./pages/QrCode/QrCode";

import { Provider } from "react-redux";
import store from "./redux/store";

import "./utils/localization/i18next/i18next.ts";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (isOpenTelegram()) {
  root.render(
    <TonConnectUIProvider
      manifestUrl={`${process.env.NODE_ENV == "production" ? process.env.REACT_APP_URL_PRODUCTION : process.env.REACT_APP_URL_DEVELOPMENT}/tonconnect-manifest.json`}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/catacomBot/catacomApp'
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </TonConnectUIProvider>
  );
} else {
  root.render(<QrCode />);
}
