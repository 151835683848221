//@ts-nocheck

import { useTranslation } from 'react-i18next';
import PriceUpButton from '../../HeroPriceUpButton/HeroPriceUpButton'
import './HeroLinesViewLocked.scss'

function HeroLinesViewLocked({ index, userHero }) {

    const { t, i18n } = useTranslation();

    const nameRu = userHero.hero.nameRu
    const nameEn = userHero.hero.nameEn

    const image = `/img/heroes/${userHero.hero.pathImage}.png`
    const rank = userHero.rank
    const level = userHero.level
    const price = userHero.hero.basePrice.toString()

    const descriptionRu = userHero.hero.descriptionRu
    const descriptionEn = userHero.hero.descriptionEn

    return (
        <div className={'border_box_locked'}>
            <div className={'line_box_locked'}>
                <div className={'d-flex'}>
                    <div className={'position-relative'} style={{ marginTop: '17px' }}>
                        <img src={image} className={'line_img_lock'} />
                        {userHero.hero.id !== 1 &&
                            <div className={'position-absolute top-0 left-0'}>
                                <img src={`/img/ranks/rank${rank}.svg`} className={'line_img_lock'} />
                            </div>
                        }
                        {userHero.hero.prestige !== "NO" &&
                            < div className={'unlocked_border'}>
                                <svg height='36vw' viewBox="0 0 304 304" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M302 152L274.017 179.983V274.017H179.983L152 302L124.017 274.017H29.9832V179.983L2 152L29.9832 124.017V29.9832H124.017L152 2L179.983 29.9832H274.017V124.017L302 152Z" stroke="#B28E4C" stroke-width="5" stroke-miterlimit="10" />
                                </svg>
                            </div>
                        }
                    </div>
                    <div className={'ms-3 w-100 d-flex flex-column justify-content-between'}>
                        <div>
                            <div className='hero-lines-view-locked-name'>
                                {i18n.language === 'en' &&
                                    (nameEn)
                                }
                                {i18n.language === 'ru' &&
                                    (nameRu)
                                }
                            </div>
                            <p className='hero-lines-view-locked-description'>
                                {i18n.language === 'en' &&
                                    (descriptionEn)
                                }
                                {i18n.language === 'ru' &&
                                    (descriptionRu)
                                }
                            </p>
                        </div>
                        <div className='d-flex flex-column w-100 justify-content-between'>
                            <div className={'w-100 priceup_button_locked priceup_button d-flex align-items-end justify-content-end mt-2'}>
                                <PriceUpButton
                                    heroId={userHero.hero.id}
                                    heroLevel={level}
                                    heroBasePrice={userHero.hero.basePrice}
                                    heroPriceRate={userHero.hero.priceRate}
                                    heroRank={rank}
                                    heroName={userHero.hero.nameRu}
                                    text={t("heroPriceUpButton.summon")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HeroLinesViewLocked