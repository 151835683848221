//@ts-nocheck

import TonPromoBalance from '../../components/TonPromoBalance/TonPromoBalance'
import TonPromoBalanceComb from '../../components/TonPromoBalanceComb/TonPromoBalanceComb'
import TonPromoSections from '../../components/TonPromoSections/TonPromoSections'
import './TonPromo.scss'

export default function TonPromo() {
  return (
    <main className='h-100 ton-promo-background main-container'>
        <div className='ton-promo-container w-100 h-100 d-flex flex-column overflow-hidden'>
            
            <div className='w-100 d-flex justify-content-between'>
              <TonPromoBalance/>
              <TonPromoBalanceComb/>
            </div>
            <TonPromoSections/>

        </div>
    </main>
  )
}