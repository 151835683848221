import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function get() {
  return axios.get(
    apiURL + '/api/v1/chest',
    axiosConfig
  )
}

export async function open(chestId: number) {
  return axios.post(
    apiURL + '/api/v1/chest/open',
    {
      chestId: chestId,  
    },
    axiosConfig
  )
}