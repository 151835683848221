//@ts-nocheck

import { useQuery } from 'react-query'
import { getUserQuestSocial } from '../../api/userQuestSocial'
import QuestQuestsItem from '../QuestQuestsItem/QuestQuestsItem'
import './QuestQuests.scss'
import { useState } from 'react'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { initDataUserQuestCount } from '../../utils/initData'
import { useTranslation } from 'react-i18next'

function QuestQuests() {

    const { t } = useTranslation() 

    const { data, isLoading, isError, isSuccess, isFetching } = useQuery(
        ['userQuestSocial'],
        () => getUserQuestSocial(),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                initDataUserQuestCount()
            }
        }
    )

    return (
        <div className='quests-container overflow-auto w-100'>

            {isSuccess &&
                <>
                    {data.questSocial.map((questSocialEl, index) => {
                        return (
                            <QuestQuestsItem
                                key={questSocialEl.id}
                                id={questSocialEl.id}
                                title={questSocialEl.title}
                                link={questSocialEl.link}
                                amount={questSocialEl.amount}
                                pathImage={questSocialEl.pathImage}
                                completedProps={questSocialEl.completed}
                                logo={questSocialEl.logo}
                                titleEn={questSocialEl.titleEn}
                                type={questSocialEl.type}
                                active={questSocialEl.active}
                            />
                        )
                    })
                    }
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("questQuests.dataFetchError")} />
            }

        </div>
    )
}

export default QuestQuests