//@ts-nocheck

import { useQuery } from 'react-query'
import { getFrensRating } from '../../../../api/refferalRewardTon'
import TonPromoSectionsFrensUser from '../TonPromoSectionsFrensUser/TonPromoSectionsFrensUser'
import './TonPromoSectionsFrensRating.scss'
import TonPromoSectionsFrensRatingItem from './TonPromoSectionsFrensRatingItem/TonPromoSectionsFrensRatingItem'
import { numberFormat } from '../../../../utils/NumberFormat'
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../../DataFetchError/DataFetchError'
import { useTranslation } from 'react-i18next'

export default function TonPromoSectionsFrensRating() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['tonPromoFrensRating'],
        () => getFrensRating(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='ton-promo-sections-frens-rating overflow-auto h-100'>

            {isSuccess &&
                data.ratingFrens.map((ratingFrensItem, index) => {
                    return (
                        <TonPromoSectionsFrensRatingItem
                            key={index}
                            item={ratingFrensItem}
                        />
                    )
                })
            }

            {(isSuccess && data.ratingFrens.length === 0) &&
                <div className='w-100 h-100 text-white d-flex align-items-center justify-content-center text-center'>
                    {t("tonPromoSectionsFrensRating.soon")}
                </div>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("ratingContainer.dataFetchError")} />
            }

        </div>
    )
}