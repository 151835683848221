//@ts-nocheck

import { useTranslation } from 'react-i18next'
import { numberFormat } from '../../utils/NumberFormat'
import { getMaxLevelByRanks } from '../../utils/ranks'
import { getInitData } from '../../utils/telegram'
import PriceUpButton from '../HeroPriceUpButton/HeroPriceUpButton'
import './HeroOffcanvas.scss'

import BigNumber from "bignumber.js";

export default function HeroOffcanvas({ index, userHero }) {

    const { t, i18n } = useTranslation()

    const initData = getInitData()

    const nameRu = userHero.hero.nameRu === "User" ? initData.user?.firstName + " " + initData.user?.lastName : userHero.hero.nameRu
    const nameEn = userHero.hero.nameRu === "User" ? initData.user?.firstName + " " + initData.user?.lastName : userHero.hero.nameEn

    const descriptionRu = userHero.hero.descriptionRu
    const descriptionEn = userHero.hero.descriptionEn

    const image = `/img/heroes/${userHero.hero.pathImage}.png`
    const rank = userHero.rank
    const level = userHero.level
    const damageType = userHero.hero.damageType
    const damage = userHero.hero.baseDamage * userHero.level

    const ipsKF = 0.15
    const ips = level === 1 ? new BigNumber(userHero.hero.baseDamage).multipliedBy(ipsKF).dividedBy(2).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN).toString() : new BigNumber(userHero.hero.baseDamage).multipliedBy(new BigNumber(level - 1) * new BigNumber(ipsKF)).decimalPlaces(0, BigNumber.ROUND_HALF_EVEN).toString()

    var race = t("races." + userHero.hero.race)

    //fix-me
    var prestige = ""
    if (userHero.hero.prestige === "NO")
        prestige = t("heroOffcanvas.prestigeBasic")

    const price = level === 1 ? userHero.hero.basePrice : (userHero.hero.basePrice + level) * Math.pow(userHero.hero.damageRate, level - 1)

    const elite = userHero.hero.elite

    return (
        <div className={'offcanvas offcanvas-bottom m-0 d-flex flex-column justify-content-end bg-transparent hero-offcanvas-main-container'} tabIndex="-1" id={"offcanvasBottomHero" + index} aria-labelledby="offcanvasBottomLabel">
            <div className='h-auto'>
                <div className={'offcanvas_cont position-relative h-auto'}>

                    {userHero.hero.elite &&
                        <img width={'100%'} height={'auto'} src={`/img/heroes/background/${userHero.hero.pathImage}.png`} style={{ zIndex: 1, opacity: '70%' }} />
                    }
                    {!userHero.hero.elite &&
                        <img width={'100%'} height={'auto'} src={`/img/heroes/background/default.png`} style={{ zIndex: 1, opacity: '0%' }} />
                    }

                    <div className='w-100 h-100 position-absolute top-50 start-50 translate-middle hero-offcanvas-padding' style={{ zIndex: 2 }}>
                        <div className="w-100">
                            <svg className='position-absolute top-0 end-0' width="15" height="15" viewBox="0 0 12 12"
                                fill="none" xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas"
                                aria-label="Закрыть" style={{ cursor: 'pointer', zIndex: 2, marginTop: '5px', marginRight: '5px' }}>
                                <path d="M1.03613 1.00055L11.0361 11" stroke="#B0B0B0" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path d="M1 10.9987L10.9991 1" stroke="#B0B0B0" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="h-100 m-0 p-0 d-flex flex-column justify-content-between">
                            <div>
                                <div className={'d-flex position-relative m-auto gap-3 pb-3'}>
                                    <div className={'position-relative'}>
                                        <img src={image} className={'offcanvas_img'} />
                                        {userHero.hero.id !== 1 &&
                                            <div className={'position-absolute top-0 left-0'}>
                                                <img src={`/img/ranks/rank${rank}.svg`} className={'offcanvas_img'} />
                                            </div>
                                        }
                                    </div>
                                    <div className={''}>
                                        <div className='hero-offcanvas-name'>
                                            {i18n.language === 'en' &&
                                                (nameEn)
                                            }
                                            {i18n.language === 'ru' &&
                                                (nameRu)
                                            }
                                        </div>
                                        <div className={'hero-offcanvas-description w-100'}>
                                            {i18n.language === 'en' &&
                                                (descriptionEn)
                                            }
                                            {i18n.language === 'ru' &&
                                                (descriptionRu)
                                            }
                                        </div>

                                        <div className={'hero-offcanvas-race w-100'}>{t("heroOffcanvas.race")} <span className='hero-offcanvas-race-value'>{race}</span></div>
                                        <div className={'hero-offcanvas-alliance position-relative'} style={{ width: 'fit-content' }}>
                                            <span style={{ opacity: '30%' }}>{t("heroOffcanvas.alliance")}</span> <span style={{ opacity: '30%' }} className='hero-offcanvas-alliance-value'>{"-"}</span>
                                            <svg className='position-absolute top-50 start-50 translate-middle' width="15" height="15" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.00692 13.2099C7.55979 13.2099 8.00798 12.7849 8.00798 12.2607C8.00798 11.7365 7.55979 11.3116 7.00692 11.3116C6.45405 11.3116 6.00586 11.7365 6.00586 12.2607C6.00586 12.7849 6.45405 13.2099 7.00692 13.2099Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.00684 12.2607V14.1589" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0156 6.69828V4.38223" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0155 6.69827L13.5405 8.43222" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.8799 5.42639L15.0156 6.6983" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.0156 6.69827L16.4906 8.43222" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M17.1513 5.42639L15.0156 6.6983" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.0135 11.0342V16.5318C13.0135 17.233 12.4181 17.7975 11.6786 17.7975H2.33487C1.59532 17.7975 1 17.233 1 16.5318V9.57094C1 8.86972 1.59532 8.30527 2.33487 8.30527H10.1353" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.01465 4.79695C3.01465 2.70003 4.80756 1.00006 7.01913 1.00006C9.23071 1.00006 11.0236 2.70003 11.0236 4.79695" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.0234 4.79706V6.06237" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.01465 4.48062V6.0622" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        {(userHero.hero.type === "COMBINED" || userHero.hero.type === "DAILY") &&
                                            <div className={'hero-offcanvas-fragments w-100'}>{t("heroOffcanvas.fragments")} <span className={`hero-offcanvas-fragments-value ${userHero.count >= userHero.hero.countFragments ? 'hero-offcanvas-fragments-green' : ''}`}>{userHero.count + " / " + userHero.hero.countFragments}</span></div>
                                        }
                                    </div>
                                </div>

                                <div className='hero-offcanvas-abilities d-flex justify-content-center'>
                                    <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                        <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                        <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <svg style={{ marginRight: '18px' }} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                        <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 9.6398V1H9.6398M9.6398 41H1V32.3602M32.3612 41H41V32.3602M32.3612 1H41V9.6398" stroke="#B28E4C" strokeOpacity="0.2" strokeMiterlimit="20" strokeLinecap="round" />
                                        <path d="M19.7283 26.8064C20.7157 26.8064 21.5162 26.0474 21.5162 25.1112C21.5162 24.175 20.7157 23.416 19.7283 23.416C18.7409 23.416 17.9404 24.175 17.9404 25.1112C17.9404 26.0474 18.7409 26.8064 19.7283 26.8064Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7275 25.1113V28.5015" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1765V11.04" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0317 15.1768L31.3975 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.2178 12.9053L34.0321 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.0322 15.1768L36.6664 18.2736" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M37.8466 12.9053L34.0322 15.1769" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M30.4558 22.9208V32.7394C30.4558 33.9917 29.3926 34.9998 28.0718 34.9998H11.3841C10.0632 34.9998 9 33.9917 9 32.7394V20.3073C9 19.055 10.0632 18.0469 11.3841 18.0469H25.3154" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.7812C12.5986 8.03611 15.8007 5 19.7506 5C23.7004 5 26.9025 8.03611 26.9025 11.7812" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M26.9023 11.7812V14.0411" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.5986 11.2158V14.0405" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>

                                <div className={'offcanvas_indicators_box d-flex justify-content-between'}>
                                    <div className={''}>
                                        <p className='hero-offcanvas-level'>{t("heroOffcanvas.level")} <b>{level}{(userHero.hero.nameRu !== "User" ? "/200" : "")}</b></p>
                                        <p className='hero-offcanvas-damage' style={{ opacity: '100%' }}>{t("heroOffcanvas.сpc")} {numberFormat(damage)}</p>
                                        <p className='hero-offcanvas-damage' style={{ opacity: '100%' }}>{t("heroOffcanvas.сps")} {numberFormat(ips)}</p>
                                        <p className='hero-offcanvas-rank'>{t("heroOffcanvas.rank")} {rank === 0 ? t("heroOffcanvas.noRank") : rank}</p>
                                        <div className='position-relative'>
                                            <svg className='position-absolute top-50 start-50 translate-middle' width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.00671 11.1764C6.46751 11.1764 6.84105 10.8222 6.84105 10.3853C6.84105 9.94836 6.46751 9.59418 6.00671 9.59418C5.54591 9.59418 5.17236 9.94836 5.17236 10.3853C5.17236 10.8222 5.54591 11.1764 6.00671 11.1764Z" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.00635 10.3854V11.9675" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.6816 5.74907V3.81873" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.6815 5.74915L11.4521 7.19433" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.9019 4.68915L12.6819 5.74924" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M12.6816 5.74915L13.9109 7.19433" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.4617 4.68915L12.6816 5.74924" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M11.0128 9.36306V13.9451C11.0128 14.5295 10.5166 15 9.90022 15H2.11257C1.49617 15 1 14.5295 1 13.9451V8.14345C1 7.55901 1.49617 7.08856 2.11257 7.08856H8.61392" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.6792 4.16457C2.6792 2.41686 4.17353 1 6.01679 1C7.86006 1 9.35438 2.41686 9.35438 4.16457" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.35449 4.16461V5.2192" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.6792 3.90076V5.21894" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='hero-offcanvas-prestige'>{t("heroOffcanvas.prestigeLevel")} {prestige}</p>
                                        </div>
                                    </div>
                                    <div className={'d-flex align-items-start'}>
                                        <svg width="121" height="45" viewBox="0 0 121 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.2">
                                                <path d="M119.141 3.87404L112.577 1.21559C111.876 0.928135 111.091 0.928135 110.39 1.21559L103.652 3.87404C103.457 3.95054 103.29 4.085 103.173 4.25933C103.057 4.43366 102.996 4.6395 103 4.84915V11.2489C103 16.9764 108.953 21 111.509 21C114.065 21 119.813 16.961 119.813 11.2489V4.84915C119.815 4.63707 119.752 4.42945 119.632 4.25484C119.511 4.08023 119.34 3.9472 119.141 3.87404Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <g opacity="0.2">
                                                <path d="M58.6557 13.9859C58.2555 13.9859 57.7299 14.1872 57.7299 14.6796C57.7299 15.0956 58.1444 15.4328 58.6557 15.4328C59.167 15.4328 59.5814 15.0955 59.5814 14.6796C59.5814 14.1872 59.0535 13.9859 58.6557 13.9859ZM57.6793 19.6254V17.0305C56.579 16.8634 55.619 16.4206 55 15.7948L55.4785 14.5743H56.4199V4.5699C56.4199 3.71636 57.7763 1.33738 58.6557 1C59.5351 1.33738 60.8914 3.71631 60.8914 4.5699V14.5743H61.8329L62.3114 15.7948C61.6925 16.4205 60.7324 16.8633 59.6321 17.0305V19.6254H59.9194C59.9059 20.1495 59.7603 20.6438 59.5145 20.9999H57.7968C57.551 20.6437 57.4054 20.1494 57.3918 19.6254L57.6793 19.6254Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M58.6558 13.986V5.08643" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M67.2524 8.01407C67.6525 8.01407 68.1781 7.81284 68.1781 7.32043C68.1781 6.90443 67.7637 6.5672 67.2524 6.5672C66.7411 6.5672 66.3266 6.90448 66.3266 7.32043C66.3266 7.81284 66.8546 8.01407 67.2524 8.01407ZM68.2287 2.37458V4.96951C69.329 5.13663 70.2891 5.5794 70.9081 6.2052L70.4296 7.42571H69.4881V17.4301C69.4881 18.2837 68.1318 20.6626 67.2524 21C66.373 20.6626 65.0166 18.2837 65.0166 17.4301V7.42571H64.0752L63.5967 6.20525C64.2156 5.57951 65.1757 5.13669 66.276 4.96956V2.37463H65.9886C66.0022 1.85053 66.1478 1.35622 66.3936 1.00012H68.1112C68.357 1.35633 68.5026 1.85058 68.5162 2.37463L68.2287 2.37458Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M67.2524 8.01416V16.9137" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <path opacity="0.2" d="M1.64522 5.05871C-2.22698 12.551 12.5507 21 13.6044 21C14.7616 21 29.4293 12.551 25.5636 5.05871C23.8635 2.17557 19.4224 -2.41418 13.6044 5.05871C10.1266 0.00998402 4.72875 -0.384346 1.64522 5.05871Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
                                            <path opacity="0.2" d="M59.476 34.888C59.476 33.936 59.742 33.2127 60.274 32.718C60.806 32.2233 61.534 31.976 62.458 31.976C63.382 31.976 64.1007 32.2373 64.614 32.76C65.1273 33.2733 65.384 33.9873 65.384 34.902V39.396C65.384 39.9653 65.258 40.4553 65.006 40.866C64.7633 41.2767 64.418 41.594 63.97 41.818C63.5313 42.042 63.0227 42.154 62.444 42.154C61.8933 42.154 61.394 42.0513 60.946 41.846C60.498 41.6407 60.1387 41.3327 59.868 40.922C59.6067 40.5113 59.476 40.0027 59.476 39.396V34.888ZM60.274 39.382C60.274 39.83 60.3673 40.2033 60.554 40.502C60.7407 40.8007 60.9927 41.0293 61.31 41.188C61.6367 41.3373 62.0053 41.412 62.416 41.412C62.8453 41.412 63.2233 41.342 63.55 41.202C63.8767 41.0527 64.1333 40.8287 64.32 40.53C64.5067 40.222 64.6 39.8393 64.6 39.382V34.762C64.6 34.3047 64.5113 33.9267 64.334 33.628C64.1567 33.3293 63.9047 33.1053 63.578 32.956C63.2513 32.7973 62.864 32.718 62.416 32.718C61.9867 32.718 61.6087 32.7973 61.282 32.956C60.9647 33.1053 60.7173 33.3293 60.54 33.628C60.3627 33.9267 60.274 34.3047 60.274 34.762V39.382Z" fill="#B0B0B0" />
                                            <path opacity="0.2" d="M10.476 34.888C10.476 33.936 10.742 33.2127 11.274 32.718C11.806 32.2233 12.534 31.976 13.458 31.976C14.382 31.976 15.1007 32.2373 15.614 32.76C16.1273 33.2733 16.384 33.9873 16.384 34.902V39.396C16.384 39.9653 16.258 40.4553 16.006 40.866C15.7633 41.2767 15.418 41.594 14.97 41.818C14.5313 42.042 14.0227 42.154 13.444 42.154C12.8933 42.154 12.394 42.0513 11.946 41.846C11.498 41.6407 11.1387 41.3327 10.868 40.922C10.6067 40.5113 10.476 40.0027 10.476 39.396V34.888ZM11.274 39.382C11.274 39.83 11.3673 40.2033 11.554 40.502C11.7407 40.8007 11.9927 41.0293 12.31 41.188C12.6367 41.3373 13.0053 41.412 13.416 41.412C13.8453 41.412 14.2233 41.342 14.55 41.202C14.8767 41.0527 15.1333 40.8287 15.32 40.53C15.5067 40.222 15.6 39.8393 15.6 39.382V34.762C15.6 34.3047 15.5113 33.9267 15.334 33.628C15.1567 33.3293 14.9047 33.1053 14.578 32.956C14.2513 32.7973 13.864 32.718 13.416 32.718C12.9867 32.718 12.6087 32.7973 12.282 32.956C11.9647 33.1053 11.7173 33.3293 11.54 33.628C11.3627 33.9267 11.274 34.3047 11.274 34.762V39.382Z" fill="#B0B0B0" />
                                            <path opacity="0.2" d="M107.476 34.888C107.476 33.936 107.742 33.2127 108.274 32.718C108.806 32.2233 109.534 31.976 110.458 31.976C111.382 31.976 112.101 32.2373 112.614 32.76C113.127 33.2733 113.384 33.9873 113.384 34.902V39.396C113.384 39.9653 113.258 40.4553 113.006 40.866C112.763 41.2767 112.418 41.594 111.97 41.818C111.531 42.042 111.023 42.154 110.444 42.154C109.893 42.154 109.394 42.0513 108.946 41.846C108.498 41.6407 108.139 41.3327 107.868 40.922C107.607 40.5113 107.476 40.0027 107.476 39.396V34.888ZM108.274 39.382C108.274 39.83 108.367 40.2033 108.554 40.502C108.741 40.8007 108.993 41.0293 109.31 41.188C109.637 41.3373 110.005 41.412 110.416 41.412C110.845 41.412 111.223 41.342 111.55 41.202C111.877 41.0527 112.133 40.8287 112.32 40.53C112.507 40.222 112.6 39.8393 112.6 39.382V34.762C112.6 34.3047 112.511 33.9267 112.334 33.628C112.157 33.3293 111.905 33.1053 111.578 32.956C111.251 32.7973 110.864 32.718 110.416 32.718C109.987 32.718 109.609 32.7973 109.282 32.956C108.965 33.1053 108.717 33.3293 108.54 33.628C108.363 33.9267 108.274 34.3047 108.274 34.762V39.382Z" fill="#B0B0B0" />
                                        </svg>

                                        {userHero.hero.nameRu !== "User" &&
                                            <div className='d-flex flex-column align-items-center' style={{ marginLeft: '27px' }}>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.2222 1L16.5556 7.66667L11 21" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.77767 1L5.44434 7.66667L10.9999 21" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M16.5556 1H5.44444L1 7.66667L11 21L21 7.66667L16.5556 1Z" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M1 7.66669H21" stroke="#B28E4C" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <div className='hero-offcanvas-income-crystal'>
                                                    {userHero.hero.incomeCrystal}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div>
                                {(level < getMaxLevelByRanks(rank) || userHero.hero.nameRu === "User") &&
                                    <div className={'mt-3 w-100 justify-content-end d-flex'}>
                                        <PriceUpButton
                                            heroId={userHero.hero.id}
                                            heroLevel={level}
                                            text={t("heroPriceUpButton.upgrade")}
                                            heroBasePrice={userHero.hero.basePrice}
                                            heroPriceRate={userHero.hero.priceRate}
                                            heroRank={rank}
                                            heroName={userHero.hero.nameRu}
                                            offCanvas={true}
                                        />
                                    </div>
                                }
                                {(level == getMaxLevelByRanks(rank) && userHero.hero.nameRu !== "User") &&
                                    <div className={'mt-3 w-100 justify-content-end d-flex'}>
                                        <div className={'priceup_button d-flex align-items-end'}>
                                            <div className={`price-up-button ${"price-up-button-dis"} position-relative`}>
                                                <svg width={true ? '177' : '122'} height={true ? '50' : '40'} viewBox="0 0 130 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M129 19.8599L110.945 38.7166H29.0301L8.30322 19.8599L29.0301 1H110.945L129 19.8599Z" stroke="#B0B0B0" strokeMiterlimit="10" />
                                                    <path d="M122.697 20.1433L104.641 39H22.7269L2 20.1433L22.7269 1.28339H104.641L122.697 20.1433Z" stroke="#B0B0B0" strokeWidth="2" strokeMiterlimit="10" />
                                                </svg>
                                                <div className={'w-100 priceup_text position-absolute top-50 start-50 translate-middle'}>
                                                    <div className={`price-up-button-text ${true ? 'price-up-button-text-offCanvas' : ''}`} style={{ marginBottom: '1px', fontSize: '18px', textAlign: 'center' }}>{t("heroLinesViewUnlocked.buttonRank")}</div>
                                                    <div className={'d-flex align-items-center justify-content-center'}>
                                                        <img width={true ? 16.46 : 13} height={true ? 18 : 14} src={'/img/loot/stone-icon.png'} />
                                                        <div className={`ms-1 price-up-button-price ${true ? 'price-up-button-price-offCanvas' : ''}`}>{numberFormat(BigNumber(userHero.hero.basePrice).multipliedBy(5).toFixed())}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}