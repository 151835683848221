import { useTranslation } from 'react-i18next'
import './RefferalInfo.scss'

function RefferalInfo() {

  const { t } = useTranslation()

  return (
    <div className='refferal-info w-100'>
        <div className='refferal-info-text d-flex flex-column justify-content-center align-items-center'>
            {t("refferalInfo.text")}
        </div>
    </div>
  )
}

export default RefferalInfo