//@ts-nocheck

import { useQuery } from 'react-query'
import './TonPromoSectionsNFTsItems.scss'
import TonPromoSectionsNFTsItemsItem from './TonPromoSectionsNFTsItemsItem/TonPromoSectionsNFTsItemsItem'
import { get } from '../../../../api/shopNft'
import DataFetchLoader from '../../../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../../../DataFetchError/DataFetchError'
import { useTranslation } from 'react-i18next'

export default function TonPromoSectionsNFTsItems() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ['tonPromoNFTsItems'],
        () => get(),
        {
            keepPreviousData: true,
        }
    )

    return (
        <div className='ton-promo-sections-nfts-items w-100 h-100 d-flex flex-wrap overflow-auto' style={{ paddingBottom: '115px', gap: '2%' }}>
            {isSuccess &&
                data.shopNfts.map((shopNft, index) => {
                    return (
                        <TonPromoSectionsNFTsItemsItem
                            key={index}
                            item={shopNft}
                        />
                    )
                })
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("ratingContainer.dataFetchError")} />
            }
        </div>
    )
}
