import './CoinEnergyBarArrow.scss'

export default function CoinEnergyBarArrow() {
    return (
        <div className='coin-energy-bar-arrow d-flex'>
            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3397 15C11.5699 16.3333 9.64544 16.3333 8.87564 15L0.215393 0L21 0L12.3397 15Z" fill="#171027" />
            </svg>
        </div>
    )
}