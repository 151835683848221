//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsNFTsItemsItem.scss'
import ToastInfo from '../../../../Toasts/ToastInfo/ToastInfo'

export default function TonPromoSectionsNFTsItemsItem({ item }) {

  const { t, i18n } = useTranslation()

  return (
    <div onClick={() => ToastInfo(t("tonPromoSectionsNFTsItemsItem.toastInfo"), true)} className='ton-promo-sections-nfts-items-item d-flex flex-column overflow-hidden'>

      <img className='overflow-hidden' src={`/img/heroes/${item.pathImage}.png`} width={'100%'} height={'auto'} style={{borderTopLeftRadius: '4px', borderTopRightRadius: '4px', filter: 'blur(4px)'}}/>

      <div className='w-100 position-relative'>
        
        <div className='ton-promo-sections-nfts-items-item-name'>
          {i18n.language === 'en' &&
            (item.nameEn)
          }
          {i18n.language === 'ru' &&
            (item.nameRu)
          }
        </div>

        <div className='d-flex flex-column align-items-center'>
          <div className='ton-promo-sections-nfts-items-item-price d-flex align-items-center justify-content-between'>
            <div>
              {t("tonPromoSectionsNFTsItemsItem.price")}
            </div>
            <div>
              <span style={{ color: "#ffffff", fontSize: '12px', marginRight: '2.5px' }}>{item.price}</span>
              <img width={9.5} height={12} src='/img/ton/ton-icon.png' />
            </div>
          </div>
          <div className='ton-promo-sections-nfts-items-item-owner d-flex align-items-center justify-content-between'>
            {t("tonPromoSectionsNFTsItemsItem.owner")}
            <span style={{ color: "#ffffff", fontSize: '12px' }}>{"—"}</span>
          </div>
        </div>

        <svg className='position-absolute top-50 start-50 translate-middle' width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.7278 22.8064C12.7152 22.8064 13.5157 22.0474 13.5157 21.1112C13.5157 20.175 12.7152 19.416 11.7278 19.416C10.7404 19.416 9.93994 20.175 9.93994 21.1112C9.93994 22.0474 10.7404 22.8064 11.7278 22.8064Z" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.7275 21.1116V24.5017" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M26.0317 11.177V7.04053" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M26.0314 11.177L23.3972 14.2738" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22.2173 8.90576L26.0316 11.1774" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M26.0317 11.177L28.6659 14.2738" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M29.8461 8.90576L26.0317 11.1774" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M22.4558 18.9208V28.7394C22.4558 29.9917 21.3926 30.9998 20.0718 30.9998H3.38406C2.06322 30.9998 1 29.9917 1 28.7394V16.3073C1 15.055 2.06322 14.0469 3.38406 14.0469H17.3154" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.5979 7.78118C4.5979 4.03611 7.80001 1 11.7498 1C15.6997 1 18.9018 4.03611 18.9018 7.78118" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.9011 7.78174V10.0416" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.59717 7.21606V10.0407" stroke="#B0B0B0" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </div>

    </div>
  )
}
