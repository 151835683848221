//@ts-nocheck

import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getByUserId() {
    const { data } = await axios.get(
        apiURL + '/api/v1/mine',
        axiosConfig
    )
    return data
}

export async function create(dataObj) {    
    const { data } = await axios.post(
        apiURL + '/api/v1/mine/create',
        {
            mineId: dataObj.mineId,
            heroIds: dataObj.heroIds    
        },
        axiosConfig
    )
    return data
}

export async function claim(mineId: number) {
    const { data } = await axios.post(
        apiURL + '/api/v1/mine/claim',
        {
            mineId: mineId,    
        },
        axiosConfig
    )
    return data
}