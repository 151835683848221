//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeInvoiceHistory.scss'
import { useQuery } from 'react-query';
import DataFetchLoader from '../../../../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../../../../DataFetchError/DataFetchError';
import { getByUserId } from '../../../../../api/userTonInvoice';
import TonPromoSectionsExchangeInvoiceHistoryItem from './TonPromoSectionsExchangeInvoiceHistoryItem/TonPromoSectionsExchangeInvoiceHistoryItem';

export default function TonPromoSectionsExchangeInvoiceHistory() {

    const { t, i18n } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["userTonInvoiceHistory"],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    );

    if (isSuccess) {
        console.log(data);

    }

    return (
        <div className='ton-promo-sections-exchange-invoice-history w-100'>

            <div className='ton-promo-sections-exchange-invoice-history-header d-flex w-100'>
                <div className='ton-promo-sections-exchange-invoice-history-header-item w-25 d-flex justify-content-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistory.header.date")}
                </div>
                <div className='ton-promo-sections-exchange-invoice-history-header-item w-25 d-flex justify-content-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistory.header.ton")}
                </div>
                <div className='ton-promo-sections-exchange-invoice-history-header-item w-25 d-flex justify-content-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistory.header.wallet")}
                </div>
                <div className='ton-promo-sections-exchange-invoice-history-header-item w-25 d-flex justify-content-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistory.header.status")}
                </div>
            </div>

            {isSuccess &&
                (data.userTonInvoice.map((item, index) => {
                    return (
                        <TonPromoSectionsExchangeInvoiceHistoryItem
                            key={index}
                            item={item}
                        />
                    )
                })
                )
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("questQuests.dataFetchError")} />
            }

        </div>
    )
}