//@ts-nocheck

import { useTranslation } from 'react-i18next';
import './TonPromoBalanceComb.scss'
import { useQuery } from 'react-query';
import DataFetchError from '../DataFetchError/DataFetchError';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import { getByUserId } from '../../api/userComb';

export default function TonPromoBalanceComb() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess } = useQuery(
        ["userComb"],
        () => getByUserId(),
        {
            keepPreviousData: true,
        }
    );

    return (
        <div className="ton-promo-balance-comb">
            <div className="ton-promo-balance-comb-title">
                {t("tonPromoBalanceComb.title")}
            </div>
            <div className="d-flex align-items-center justify-content-end">
                <img
                    src="/img/comb/comb-icon.png"
                    width={22}
                    height={22}
                    style={{ marginRight: "5px" }}
                />

                {isSuccess && (
                    <>
                        {data.userComb !== null && (
                            <span className="ton-promo-balance-comb-count">
                                {data.userComb.count}
                            </span>
                        )}
                        {data.userComb === null && (
                            <span className="ton-promo-balance-comb-count" style={{ color: '#B0B0B0', fontSize: '15px' }}>
                                {t("tonPromoBalanceComb.connectWallet")}
                            </span>
                        )}
                    </>
                )}

                {isLoading && (
                    <div>
                        <DataFetchLoader width={13} height={13} color={"#B28E4C"} />
                    </div>
                )}

                {isError && (
                    <DataFetchError text={t("tonPromoBalanceComb.dataFetchError")} />
                )}
            </div>
        </div>
    )
}