//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeInvoiceHistoryItem.scss'

export default function TonPromoSectionsExchangeInvoiceHistoryItem({ item }) {

    const { t, i18n } = useTranslation()

    return (
        <div className='ton-promo-sections-exchange-invoice-history-item w-100 d-flex align-items-center'>
            <div className='ton-promo-sections-exchange-invoice-history-header-item-date w-25 d-flex justify-content-center align-items-center'>
                {new Date(item.createdAt).toLocaleDateString()}
            </div>
            <div className='ton-promo-sections-exchange-invoice-history-header-item-ton w-25 d-flex justify-content-center align-items-center'>
                <img src='/img/ton/ton-icon.png' width={7.89} height={10} style={{ marginRight: '4px' }} />
                <span>{item.count}</span>
            </div>
            <div className='ton-promo-sections-exchange-invoice-history-header-item-wallet w-25 d-flex justify-content-center align-items-center'>
                {item.address.slice(0, 4) + "..." + item.address.substr(item.address.length - 2)}
            </div>
            {item.status === "REGISTERED" &&
                <div className='ton-promo-sections-exchange-invoice-history-header-item-status-registered w-25 d-flex justify-content-center align-items-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistoryItem.status.registered")}
                </div>
            }
            {item.status === "IN_PROCESSING" &&
                <div className='ton-promo-sections-exchange-invoice-history-header-item-status-in-processing w-25 d-flex justify-content-center align-items-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistoryItem.status.InProcessing")}
                </div>
            }
            {item.status === "COMPLETED" &&
                <div className='ton-promo-sections-exchange-invoice-history-header-item-status-completed w-25 d-flex justify-content-center align-items-center'>
                    {t("tonPromoSectionsExchangeInvoiceHistoryItem.status.completed")}
                </div>
            }
        </div>
    )
}