//@ts-nocheck
import { useSelector } from 'react-redux';
import HeroDetailsModal from '../../HeroDetailsModal/HeroDetailsModal';
import './ShopHero.scss';
import {useTranslation} from "react-i18next";

export default function ShopHero ({ shopHero }) {  
    const heroNameEn = shopHero.hero.nameEn;
    const heroNameRu = shopHero.hero.nameRu;
    const heroPathImg = shopHero.hero.pathImage;
    const heroPrice = shopHero.price;

    const { t, i18n } = useTranslation();
    const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)

    const shopHeroContainerItemBuy = () => {
        const myModal = new bootstrap.Modal(document.getElementById('heroDetailsModal' + shopHero.hero.id))
        myModal.show()
    }

    return (<>
        <div onClick={shopHeroContainerItemBuy} className='hero-container d-flex justify-content-between'>
            <div>
                <div className='shop-hero-name lh-1'>
                    {i18n.language === 'en' &&
                        (heroNameEn)
                    }
                    {i18n.language === 'ru' &&
                        (heroNameRu)
                    }
                </div>
                <div className='shop-click-details lh-1'>
                    {t("shop.click")}
                </div>
                <div className={`shop-hero-price d-flex justify-content-start align-items-center ${(BigInt(countUserLootCrystal) < BigInt(heroPrice)) ? 'shop-hero-price-red' : "text-white "} gap-1`}>
                    <img src={`img/loot/${shopHero.loot.pathImage}-icon.png`} height='16px' />
                    {heroPrice}
                </div>
            </div>
            <img src={`img/heroes/${heroPathImg}.png`} height='70px' className='shop-hero-img'/>
        </div>
        <HeroDetailsModal
            hero={shopHero.hero}
            price={shopHero.price}  
            userHero={shopHero.userHero}
        />
    </>)
}