import './ApiError.scss'
import ApiErrorIcon from './ApiErrorIcon/ApiErrorIcon'

export default function ApiError() {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center api-error flex-column position-fixed" style={{zIndex: 99999999999999}}>
      <ApiErrorIcon/>
      Произошла ошибка при загрузке данных или вход в игру временно недоступен. Попробуйте позже.
    </div>
  )
}