//@ts-nocheck
import { useTranslation } from 'react-i18next'
import './HeroLinesViewCombined.scss'
import HeroActivateButton from '../../HeroActivateButton/HeroActivateButton'

export default function HeroLinesViewCombined({ index, userHero }) {
  const { t, i18n } = useTranslation()

  const nameRu = userHero.hero.nameRu
  const nameEn = userHero.hero.nameEn
  const image = `/img/heroes/${userHero.hero.pathImage}.png`
  const rank = userHero.rank

  return (
    <div className={'border_box'}>
      <div className={'hero-lines-view-combined'}>
        <div className={'d-flex'} >
          <div className='d-flex align-items-center' style={{ marginTop: '17px' }}>
            <div className={'position-relative'}>
              <img src={image} className={'line_img'} style={{ filter: `grayscale(${userHero.count < userHero.hero.countFragments ? '100' : '0'}%)` }} />
              {userHero.hero.id !== 1 &&
                <div className={'position-absolute top-0 left-0'}>
                  <img src={`/img/ranks/rank${rank}.svg`} className={'line_img'} />
                </div>
              }
            </div>
          </div>

          <div className={'ms-4 w-100 d-flex flex-column justify-content-between'}>
            <div className=''>
              <div className='hero-lines-view-combined-name'>
                {i18n.language === 'en' &&
                  (nameEn)
                }
                {i18n.language === 'ru' &&
                  (nameRu)
                }
              </div>

              {userHero.count >= userHero.hero.countFragments &&
                <div className='hero-lines-view-combined-desc'>{t("heroLinesViewActivateCombined.descActive")}</div>
              }
              {userHero.count < userHero.hero.countFragments &&
                <div className='hero-lines-view-combined-desc'>{t("heroLinesViewActivateCombined.desc")}</div>
              }

            </div>
            {userHero.count >= userHero.hero.countFragments &&
              <div className={'priceup_button_locked w-100 d-flex justify-content-end'}>
                <HeroActivateButton heroId={userHero.hero.id} />
              </div>
            }

            {userHero.count < userHero.hero.countFragments &&
              <div className='d-flex justify-content-center'>
                <div className='hero-lines-view-combined-stat position-relative overflow-hidden w-100'>
                  <div className='hero-lines-view-combined-progress h-100' style={{ width: `${Math.round((userHero.count / userHero.hero.countFragments) * 100)}%` }}></div>
                  <div className='position-absolute lh-lg d-flex top-0 w-100 h-100 align-items-center justify-content-center text-white'>{userHero.count}/{userHero.hero.countFragments}</div>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}