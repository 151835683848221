import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function get() {
    return axios.get(
      apiURL + '/api/v1/rating',
      axiosConfig
    )
  }