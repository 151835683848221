//@ts-nocheck

import './TonPromoSectionsFrens.scss'
import TonPromoSectionsFrensInfo from './TonPromoSectionsFrensInfo/TonPromoSectionsFrensInfo'
import TonPromoSectionsFrensRating from './TonPromoSectionsFrensRating/TonPromoSectionsFrensRating'
import TonPromoSectionsFrensUser from './TonPromoSectionsFrensUser/TonPromoSectionsFrensUser'

export default function TonPromoSectionsFrens() {
  return (
    <div className='ton-promo-sections-frens h-100 d-flex flex-column'>
      <div className='d-block' style={{paddingBottom: '10px'}}>
        <TonPromoSectionsFrensInfo />
      </div>
      <TonPromoSectionsFrensRating />
    </div>
  )
}