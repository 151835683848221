//@ts-nocheck

import './HeroLinesView.scss'
import HeroLinesViewActivate from './HeroLinesViewActivate/HeroLinesViewActivate';
import HeroLinesViewCombined from './HeroLinesViewCombined/HeroLinesViewCombined';
import HeroLinesViewLocked from './HeroLinesViewLocked/HeroLinesViewLocked';
import HeroLinesViewMine from './HeroLinesViewMine/HeroLinesViewMine';
import HeroLinesViewUnlocked from './HeroLinesViewUnlocked/HeroLinesViewUnlocked';

function HeroLinesView({ userHeroes }) {
    return (
        <div>
            {userHeroes.map((userHero, index) => {
                if (userHero.status === "ACTIVE" && userHero.level !== 0 && !userHero.mine.inMine) {
                    return (
                        <HeroLinesViewUnlocked
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
                if (userHero.status === "ACTIVE" && userHero.level !== 0 && userHero.mine.inMine) {
                    return (
                        <HeroLinesViewMine
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
                if (userHero.status === "ACTIVE" && userHero.level === 0) {
                    return (
                        <HeroLinesViewLocked
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
                if (userHero.status === "NOT_ACTIVE" && userHero.hero.type === "REFFERAL") {
                    return (
                        <HeroLinesViewActivate
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
                if (userHero.status === "NOT_ACTIVE" && userHero.hero.type === "DAILY") {
                    return (
                        <HeroLinesViewActivate
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
                if (userHero.status === "NOT_ACTIVE" && userHero.hero.type === "COMBINED") {
                    return (
                        <HeroLinesViewCombined
                            key={index}
                            index={index}
                            userHero={userHero}
                        />
                    )
                }
            })
            }
        </div>
    )
}

export default HeroLinesView