import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getTimeEnd() {
    const { data } = await axios.get(
        apiURL + '/api/v1/refferal-reward-ton/get-time-end',
        axiosConfig
    )
    return data
}

export async function getSumReward() {
    const { data } = await axios.get(
        apiURL + '/api/v1/refferal-reward-ton/get-sum-reward',
        axiosConfig
    )
    return data
}

export async function getFrensRating() {
    const { data } = await axios.get(
        apiURL + '/api/v1/refferal-reward-ton/get-frens-rating',
        axiosConfig
    )
    return data
}