//@ts-nocheck

import { useTranslation } from "react-i18next";
import ToastInfo from "../Toasts/ToastInfo/ToastInfo";
import "./QuestPromoTON.scss";
import { NavLink, useNavigate } from "react-router-dom";

export default function QuestPromoTON() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <NavLink to="/ton-promo" className={'text-decoration-none position-relative'}>
      <div className="quest-promo-TON-info d-flex flex-column align-items-center justify-content-center">
        <img
          className="animate__animated animate__bounceIn animate__faster"
          src={"/img/promoTON/tons.png"}
          width={74}
          height={40}
          alt=""
        />
        <div className="quest-promo-TON-info-title animate__animated animate__bounceIn animate__faster">
          {"Promo"}
        </div>

        <svg className="position-absolute top-0 end-0" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M35.7822 15.219L20.7823 0.219024C20.6414 0.0784698 20.4504 -0.000350391 20.2513 1.17102e-06H0.751359C0.337149 -0.000701953 0.0007746 0.334477 1.16379e-06 0.748687C-0.000350398 0.948374 0.0789619 1.13998 0.22036 1.28102L34.7202 35.781C34.8611 35.9215 35.0522 36.0004 35.2512 36C35.3495 35.9999 35.4469 35.9806 35.5378 35.943C35.8182 35.827 36.0012 35.5535 36.0013 35.25V15.75C36.0016 15.551 35.9229 15.3599 35.7822 15.219Z" fill="#B13D58" />
          <path d="M15.7513 12.0001C15.3371 12.0001 15.0012 11.6645 15.0011 11.2503C15.0011 11.1998 15.0062 11.1495 15.0163 11.1001L16.0303 6.02857L13.2823 8.78109C12.9844 9.06888 12.5095 9.06058 12.2217 8.76267C11.9411 8.472 11.9411 8.01124 12.2217 7.72064L16.7217 3.22065C17.0146 2.92773 17.4895 2.92766 17.7824 3.22051C17.9595 3.39763 18.0366 3.65145 17.9877 3.89713L16.9737 8.96862L19.7232 6.2206C20.0109 5.92268 20.4858 5.91439 20.7838 6.20218C21.0817 6.48996 21.09 6.96471 20.8022 7.2627C20.7961 7.26896 20.79 7.27507 20.7838 7.28112L16.2838 11.7811C16.1425 11.922 15.9508 12.0008 15.7513 12.0001Z" fill="white" />
          <path d="M20.2513 16.5C20.0523 16.5004 19.8612 16.4216 19.7203 16.281L17.4703 14.031C17.1775 13.7382 17.1775 13.2634 17.4703 12.9705L21.9703 8.4705C22.2632 8.17772 22.7379 8.17772 23.0308 8.4705L25.2808 10.7205C25.5787 11.0083 25.587 11.483 25.2992 11.781C25.0115 12.0789 24.5367 12.0872 24.2387 11.7994C24.2325 11.7934 24.2264 11.7873 24.2203 11.781L22.5013 10.0605L19.0618 13.5L20.7808 15.219C21.0741 15.5115 21.0747 15.9864 20.7822 16.2797C20.6415 16.4207 20.4505 16.5 20.2513 16.5Z" fill="white" />
          <path d="M21.751 13.5001C21.552 13.5005 21.3609 13.4217 21.22 13.2811L19.72 11.7811C19.4323 11.4832 19.4405 11.0084 19.7385 10.7206C20.0291 10.4399 20.4899 10.4399 20.7805 10.7206L22.2805 12.2206C22.5729 12.5139 22.5722 12.9888 22.2788 13.2812C22.1387 13.421 21.9489 13.4996 21.751 13.5001Z" fill="white" />
          <path d="M26.2516 22.5C25.8373 22.4997 25.5018 22.1636 25.5021 21.7494C25.5022 21.669 25.5151 21.5892 25.5406 21.5129L26.5666 18.4364L23.49 19.4624C23.202 19.5558 22.8861 19.4691 22.686 19.2419C22.4877 19.0126 22.4468 18.6865 22.5825 18.4154L25.5825 12.4154C25.7677 12.0447 26.2183 11.8943 26.5891 12.0794C26.9598 12.2645 27.1102 12.7152 26.9251 13.0859L24.7351 17.4659L27.5175 16.5389C27.7873 16.4507 28.0838 16.5208 28.2856 16.7204C28.4867 16.9212 28.557 17.2187 28.467 17.4884L27.54 20.2694L31.92 18.0794C32.2907 17.8942 32.7413 18.0446 32.9265 18.4154C33.1117 18.7861 32.9613 19.2367 32.5905 19.4219L26.5906 22.4219C26.4853 22.4742 26.3691 22.5009 26.2516 22.5Z" fill="white" />
        </svg>


      </div>
    </NavLink>
  );
}
