//@ts-nocheck

import { useSelector } from 'react-redux';
import './CoinEnergyBarInfo.scss'
import { numberFormat } from '../../../utils/NumberFormat';
import { useTranslation } from 'react-i18next';

export default function CoinEnergyBarInfo() {

    const { t } = useTranslation()

    const userEnergyCount = useSelector((state) => state.userEnergyCount.count);

    return (
        <div className='coin-energy-bar-info d-flex flex-column align-items-start justify-content-center'>
            
            <div className='coin-energy-bar-info-text'>{t("coinEnergyBarInfo.text")}</div>
            <div className='coin-energy-bar-info-count'>{t("coinEnergyBarInfo.count")} <span>{numberFormat(userEnergyCount.toString())}</span> {t("coinEnergyBarInfo.outOf")} <span>100</span></div>
            <div className='coin-energy-bar-info-text'>{t("coinEnergyBarInfo.speed")}</div>

        </div>
    )
}
