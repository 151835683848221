//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './ShopCrystals.scss'
import { useQuery } from 'react-query'
import { get } from '../../api/shopCrystalStars'
import ShopCrystalsItem from './ShopCrystalsItem/ShopCrystalsItem'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { UseAdsgram } from '../UseAdsgram/UseAdsgram'
import { incrementUserLootCountCrystal } from '../../redux/userLootCrystalSlice/userLootCrystalSlice'
import { useDispatch } from 'react-redux'

export default function ShopCrystals() {

    const { t, i18n } = useTranslation()
    const { data, isLoading, isError, isSuccess } = useQuery(
        ['shopCrystals'],
        () => get(),
        {
            keepPreviousData: true,
        }
    )

    const dispatch = useDispatch()
    const showAd = UseAdsgram({
        blockId: '4953',
        onReward: () => dispatch(incrementUserLootCountCrystal("5")),
        onError: (error) => console.error('Error showing ad:', error),
    });

    return (
        <div className='shop-crystals w-100 d-flex flex-wrap overflow-auto' style={{ columnGap: '2%', rowGap: '8px' }}>

            <div onClick={showAd} className='shop-crystals-promo d-flex flex-column align-items-center justify-content-between position-relative'>
                <div className='w-100 d-flex flex-column align-items-center'>
                    <img src={`/img/shop/crystal/promo.png`} height={80} width={'auto'} />
                    <div className='shop-crystals-promo-name text-center'>
                        {i18n.language === 'en' &&
                            ("Promo crystals")
                        }
                        {i18n.language === 'ru' &&
                            ("Promo кристаллы")
                        }
                    </div>
                </div>
                <div className='w-100 h-100 d-flex flex-column justify-content-between'>

                    <div className='shop-crystals-promo-ad d-flex align-items-center justify-content-center position-relative'>
                        <svg width="82" height="26" viewBox="0 0 82 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M81.211 12.9115L69.8076 24.821H18.0721L4.98145 12.9115L18.0721 1H69.8076L81.211 12.9115Z" stroke="#B28E4C" stroke-miterlimit="10" />
                            <path d="M77.2296 13.0905L65.8261 25H14.0906L1 13.0905L14.0906 1.17896H65.8261L77.2296 13.0905Z" stroke="#B28E4C" stroke-miterlimit="10" />
                        </svg>
                        <div className='position-absolute'>
                          {t("shopCrystalsItem.ad")}  
                        </div>
                    </div>

                    <div className='shop-crystals-promo-name-count w-100 text-start'>
                        {t("shopCrystalsItem.count", { count: 5 })}
                    </div>
                </div>
            </div>

            {isSuccess &&
                (data.data.shopCrystal.map((item, index) => {
                    return (
                        <ShopCrystalsItem
                            key={index}
                            item={item}
                        />
                    )
                })
                )
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("questQuests.dataFetchError")} />
            }
        </div>
    )
}