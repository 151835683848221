//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

export const startLoadingSlice = createSlice({
    name: "startLoading",
    initialState: {
        isLoading: true
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
})

export const { setIsLoading } = startLoadingSlice.actions

export default startLoadingSlice.reducer