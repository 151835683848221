import './ApiErrorIcon.scss'

export default function ApiErrorIcon() {
    
    return (
        <svg className='api-error-icon' width="76" height="60" viewBox="0 0 76 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.5434 9.40135C39.0062 8.45109 46.5881 9.30782 53.6507 11.8994C60.7132 14.491 67.0498 18.7416 72.1267 24.2933C73.7788 26.097 76.4503 23.4104 74.8095 21.6104C69.3874 15.6983 62.6345 11.1633 55.1104 8.38112C47.5863 5.59895 39.5075 4.64971 31.5434 5.61205C29.1523 5.90004 29.1258 9.69312 31.5434 9.40135Z" fill="#B13D58" />
            <path d="M44.9461 23.4938C51.4352 25.0095 57.3073 28.4691 61.7782 33.4104C63.4227 35.2178 66.0942 32.5312 64.4572 30.7313C59.5262 25.3239 53.0768 21.5319 45.9541 19.8523C43.582 19.299 42.5702 22.9519 44.9461 23.5089V23.4938Z" fill="#B13D58" />
            <path d="M37.7313 52.8949C40.0752 52.8949 41.9753 50.9948 41.9753 48.6509C41.9753 46.307 40.0752 44.4069 37.7313 44.4069C35.3874 44.4069 33.4873 46.307 33.4873 48.6509C33.4873 50.9948 35.3874 52.8949 37.7313 52.8949Z" fill="#B13D58" />
            <path d="M67.1741 56.7524C67.3508 56.9291 67.4909 57.1388 67.5865 57.3696C67.6821 57.6004 67.7313 57.8478 67.7313 58.0976C67.7313 58.3474 67.6821 58.5948 67.5865 58.8256C67.4909 59.0564 67.3508 59.2661 67.1741 59.4428C66.9975 59.6195 66.7877 59.7596 66.5569 59.8552C66.3261 59.9508 66.0787 60 65.8289 60C65.5791 60 65.3317 59.9508 65.1009 59.8552C64.8701 59.7596 64.6604 59.6195 64.4837 59.4428L42.3542 37.3133C39.3183 36.4675 36.1068 36.4839 33.0796 37.3606C30.0524 38.2374 27.3293 39.9399 25.2153 42.2773C23.5859 44.0962 20.8955 41.4058 22.5249 39.6248C24.4765 37.4891 26.8536 35.7856 29.5032 34.624C32.1529 33.4623 35.0163 32.8682 37.9094 32.8799L28.9288 23.8992C23.0459 25.6124 17.7575 28.9324 13.6579 33.4861C12.0285 35.305 9.37603 32.6146 11.0054 30.7958C15.066 26.2848 20.2078 22.8813 25.9466 20.9057L18.3301 13.2892C12.641 15.9743 7.54076 19.7608 3.32453 24.4297C3.15325 24.6268 2.9431 24.7863 2.70726 24.8982C2.47142 25.0102 2.21498 25.0722 1.95404 25.0804C1.69309 25.0886 1.43327 25.0428 1.19088 24.9458C0.948488 24.8488 0.728753 24.7028 0.545461 24.5169C0.36217 24.331 0.219277 24.1092 0.12575 23.8655C0.0322233 23.6217 -0.00991991 23.3613 0.00196405 23.1005C0.013848 22.8397 0.0795032 22.5841 0.19481 22.3499C0.310117 22.1157 0.472587 21.9078 0.672027 21.7393C4.85444 17.0746 9.88153 13.2433 15.4882 10.4473L8.2885 3.2476C7.93173 2.89083 7.7313 2.40694 7.7313 1.9024C7.7313 1.39785 7.93173 0.913968 8.2885 0.557199C8.64527 0.20043 9.12916 3.75917e-09 9.6337 0C10.1382 -3.75917e-09 10.6221 0.20043 10.9789 0.557199L67.1741 56.7524Z" fill="#B13D58" />
        </svg>
    )
}   