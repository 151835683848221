//@ts-nocheck

import BigNumber from "bignumber.js";

import './CoinEnergyBar.scss';
import './Bubbles.scss';
import { useDispatch, useSelector } from "react-redux";
import AnimatedNumbers from 'react-animated-numbers';
import { numberFormat } from "../../utils/NumberFormat";
import { incrementUserEnergyCount } from "../../redux/userEnergyCountSlice/userEnergyCountSlice";
import { useEffect } from "react";
import { setColor } from "../../redux/userEnergyCountSlice/userEnergyCountSlice"

import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

import CoinEnergyBarInfo from "./CoinEnergyBarInfo/CoinEnergyBarInfo";
import CoinEnergyBarArrow from "./CoinEnergyBarArrow/CoinEnergyBarArrow";

export default function CoinEnergyBar() {

    const dispatch = useDispatch();
    const color = useSelector(state => state.userEnergyCount.color);
    const userEnergyCount = useSelector((state) => state.userEnergyCount.count);
    const userEnergyCountMax = useSelector((state) => state.userEnergyCount.countMax);
    const fillPercentUserEnergyCount = userEnergyCount / userEnergyCountMax * 100;

    useEffect(() => {
        if (userEnergyCount === 0) {
            dispatch(setColor('#B13D58'));
        }

        else if (userEnergyCount > 0 && color === '#B13D58') {
            dispatch(setColor('#B0B0B0'));
        }
    }, [userEnergyCount]);


    return (
        <Popup
            trigger={
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <div className='container-health-bar d-flex align-items-center position-relative'>
                        <div id='energy-bar' className='health-bar-img'>
                            <svg width="60" height="56" viewBox="0 0 60 56" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.9907 17.4387C17.9907 15.7336 16.6085 14.3514 14.9035 14.3514V14.3514C14.0847 14.3514 13.2995 14.6767 12.7205 15.2557L2.82882 25.1474C1.26657 26.7096 1.26674 29.2426 2.8292 30.8046L12.7205 40.6932C13.2995 41.272 14.0846 41.5972 14.9033 41.5972V41.5972C16.6084 41.5972 17.9907 40.2149 17.9907 38.5098V17.4387Z"
                                    stroke="#B28E4C" strokeMiterlimit="20"/>
                                <path
                                    d="M56.8265 25.1507L34.5042 2.82843C32.9421 1.26633 30.4095 1.26633 28.8474 2.82843L6.52508 25.1507C4.96298 26.7128 4.96298 29.2455 6.52508 30.8076L28.8474 53.1298C30.4095 54.6919 32.9421 54.6919 34.5042 53.1298L56.8265 30.8076C58.3886 29.2455 58.3886 26.7128 56.8265 25.1507Z"
                                    fill="#171027" stroke="#B28E4C" strokeWidth="2" strokeMterlimit="20"/>
                                <path id='energy_logo' d="M34.2683 34.2287C32.2889 36.9067 31.2992 38.2458 30.3758 37.9628C29.4525 37.6798 29.4525 36.0375 29.4525 32.7528L29.4526 32.4433C29.4526 31.2585 29.4526 30.6662 29.074 30.2946L29.054 30.2754C28.6673 29.9117 28.0508 29.9117 26.8177 29.9117C24.5989 29.9117 23.4895 29.9117 23.1145 29.2387C23.1083 29.2276 23.1023 29.2164 23.0964 29.205C22.7424 28.5217 23.3848 27.6526 24.6695 25.9144L27.7317 21.7713C29.711 19.0933 30.7007 17.7543 31.6241 18.0372C32.5474 18.3202 32.5474 19.9625 32.5474 23.2471V23.5568C32.5474 24.7415 32.5474 25.3339 32.926 25.7054L32.946 25.7247C33.3327 26.0884 33.9492 26.0884 35.1822 26.0884C37.4011 26.0884 38.5106 26.0884 38.8855 26.7613C38.8917 26.7724 38.8977 26.7837 38.9036 26.795C39.2576 27.4784 38.6152 28.3475 37.3304 30.0857" stroke={color} strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div id='health-bar'
                             className='health-bar w-100 d-flex justify-content-start align-items-center'>
                            <div id='health'
                                 className='health-fill h-100 d-flex align-items-center justify-content-start'
                                 style={{'width': `${fillPercentUserEnergyCount}%`}}>
                                <div className='wrapper' style={{'width': `${fillPercentUserEnergyCount - 20}%`}}>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                    <div><span className="dot"></span></div>
                                </div>
                            </div>
                            <div className='health-text'>
                                {numberFormat(userEnergyCount.toString())}
                            </div>
                        </div>
                    </div>
                </div>
            }
            position="top center"
            nested
            arrow={false}
        >
            <div style={{paddingBottom: '2px', width: '240px', paddingLeft: '5px'}}>
                <CoinEnergyBarInfo />
                <CoinEnergyBarArrow />
            </div>
        </Popup>
    )
}