//@ts-nocheck

import BigNumber from "bignumber.js"

export const getMineRewardByMine = (mine) => {
    let mineRewardHero = new BigNumber(0)
    for (const userMineHero of mine.UserMine) {
        mineRewardHero = mineRewardHero.plus(new BigNumber(userMineHero.hero.incomeCrystal))
    }
    const mineReward = mineRewardHero.plus(mineRewardHero.dividedBy(100).multipliedBy(new BigNumber(mine.reward)).decimalPlaces(0, BigNumber.ROUND_HALF_CEIL).toString())
    return mineReward.toFixed()
}

export const getMineRewardByHeroesByReward = (heroes, reward) => {
    let mineRewardHero = new BigNumber(0)
    for (const userMineHero of heroes) {
        mineRewardHero = mineRewardHero.plus(new BigNumber(userMineHero.hero.incomeCrystal))
    }
    const mineReward = mineRewardHero.plus(mineRewardHero.dividedBy(100).multipliedBy(new BigNumber(reward)).decimalPlaces(0, BigNumber.ROUND_HALF_CEIL).toString())
    return mineReward.toFixed()
}