import './QuestAirDrop.scss'


import QuestPromoTON from '../QuestPromoTON/QuestPromoTON'
import QuestPromoTONWallet from '../QuestPromoTONWallet/QuestPromoTONWallet'

function QuestAirDrop() {
  return (
    <div className='d-flex justify-content-center w-100'>
        <QuestPromoTONWallet/>
        <QuestPromoTON/>
    </div>
  )
}

export default QuestAirDrop