//@ts-nocheck

import { useTranslation } from 'react-i18next'
import './TonPromoSectionsFrensInfoReward.scss'
import { getSumReward } from '../../../../../api/refferalRewardTon';
import { useQuery } from 'react-query';
import DataFetchLoader from '../../../../DataFetchLoader/DataFetchLoader';

export default function TonPromoSectionsFrensInfoReward() {

  const { t, i18n } = useTranslation()

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['tonPromoSectionsFrensInfoSumReward'],
    () => getSumReward(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className='ton-promo-sections-frens-info-reward w-100 d-flex flex-column'>
      <div className='d-flex align-items-center justify-content-between align-items-center'>
        <div className='ton-promo-sections-frens-info-reward-title'>
          {t("tonPromoSectionsFrensInfoReward.title")}
        </div>
        <div className='ton-promo-sections-frens-info-reward-count'>
          {isSuccess &&
            <>
              {(data.sumReward)} <span> TON</span>
            </>
          }

          {isLoading &&
            <DataFetchLoader width={21} height={21} color={'#B28E4C'} />
          }
        </div>
      </div>
      <div className='ton-promo-sections-frens-info-reward-desc' style={{ maxWidth: i18n.language === 'en' ? "164px" : "170px" }}>
        {t("tonPromoSectionsFrensInfoReward.desc")}
      </div>
    </div>
  )
}