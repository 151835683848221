import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function get() {
    const { data } = await axios.get(
        apiURL + '/api/v1/user-energy', 
        axiosConfig
    )    
    return data
}

export async function increment() {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-energy/increment',
        {}, 
        axiosConfig
    )    
    return data
}

export async function decrement() {
    const { data } = await axios.post(
        apiURL + '/api/v1/user-energy/decrement',
        {}, 
        axiosConfig
    )    
    return data
}