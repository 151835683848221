//@ts-nocheck

import './TonPromoSectionsFrensInfo.scss'
import TonPromoSectionsFrensInfoReward from './TonPromoSectionsFrensInfoReward/TonPromoSectionsFrensInfoReward'
import TonPromoSectionsFrensInfoTimer from './TonPromoSectionsFrensInfoTimer/TonPromoSectionsFrensInfoTimer'

export default function TonPromoSectionsFrensInfo() {
  return (
    <div className='ton-promo-sections-frens-info d-flex'>
        <TonPromoSectionsFrensInfoReward/>
        <TonPromoSectionsFrensInfoTimer/>
    </div>
  )
}