import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function buy(heroId: number) {
    return axios.post(
        apiURL + '/api/v1/shop-hero-stars/buy',
        {
            heroId: heroId,
        },
        axiosConfig
    )
}