//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

const NAME = "userApiError"

export const userApiErrorSlice = createSlice({
    name: NAME,
    initialState: {
        error: false,
    },
    reducers: {
        setUserApiError: (state, action) => {     
            state.error = action.payload     
        },
    },
})

export const { setUserApiError } = userApiErrorSlice.actions

export default userApiErrorSlice.reducer