//@ts-nocheck

import { useQuery } from "react-query"
import { numberFormat } from "../../utils/NumberFormat"
import "./Header.scss"
import HeaderItem from "./HeaderItem/HeaderItem"

import { useDispatch, useSelector } from 'react-redux'
import { getUserLoot } from "../../api/userLoot"
import { setUserLootCountGold } from "../../redux/userLootGoldSlice/userLootGoldSlice"
import { setUserLootCountCrystal } from "../../redux/userLootCrystalSlice/userLootCrystalSlice"

function Header() {

  const dispatch = useDispatch()

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['userLoot'],
    () => getUserLoot(),
    {
      keepPreviousData: true,

      onSuccess(data) {
        for (const userLootEl of data.data) {
          if (userLootEl.name === "Gold")
            dispatch(setUserLootCountGold(userLootEl.count))
          if (userLootEl.name === "Crystal")
            dispatch(setUserLootCountCrystal(userLootEl.count))
        }
      },
    },
  )

  const countUserLootGold = useSelector((state) => state.userLootGold.count)
  const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)

  return (
    <div id='header' className="w-100 position-fixed fixed-top">
      <div className='header-container d-flex justify-content-between'>
        <HeaderItem count={numberFormat(countUserLootCrystal)} imagePath={'img/loot/header/crystal-icon.png'} disable={false} iconWidth={15} iconHeight={23} />
        <HeaderItem count={0} imagePath={'img/loot/header/soul-icon.png'} disable={true} iconWidth={32} iconHeight={23} />
        <HeaderItem count={0} imagePath={'img/loot/header/stone-icon.png'} disable={true} iconWidth={20} iconHeight={23} />
        <HeaderItem count={numberFormat(countUserLootGold)} imagePath={'img/loot/gold-icon.png'} disable={false} iconWidth={20} iconHeight={23} />
      </div>
    </div>
  )
}

export default Header