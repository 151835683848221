//@ts-nocheck
import { useTranslation } from 'react-i18next'
import './TonPromoSectionsExchangeInvoice.scss'
import TonPromoSectionsExchangeInvoiceTons from './TonPromoSectionsExchangeInvoiceTons/TonPromoSectionsExchangeInvoiceTons'
import TonPromoSectionsExchangeInvoiceWithdrawal from './TonPromoSectionsExchangeInvoiceWithdrawal/TonPromoSectionsExchangeInvoiceWithdrawal'
import TonPromoSectionsExchangeInvoiceHistory from './TonPromoSectionsExchangeInvoiceHistory/TonPromoSectionsExchangeInvoiceHistory'

export default function TonPromoSectionsExchangeInvoice() {

    return (
        <div className='ton-promo-sections-exchange-invoice'>
            <TonPromoSectionsExchangeInvoiceTons/>
            <TonPromoSectionsExchangeInvoiceWithdrawal/>
            <TonPromoSectionsExchangeInvoiceHistory/>
        </div>
    )
}
