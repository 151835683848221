//@ts-nocheck
import { useTranslation } from 'react-i18next'
import './TonPromoSectionsFrensInfoTimer.scss'
import { useQuery } from 'react-query';
import { getTimeEnd } from '../../../../../api/refferalRewardTon';
import DataFetchLoader from '../../../../DataFetchLoader/DataFetchLoader';
import React from 'react';
import { MemoCountdown } from '../../../../MemoCountdown/MemoCountdown';

export default function TonPromoSectionsFrensInfoTimer() {

  const { t } = useTranslation()

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['tonPromoSectionsFrensInfoTimeEnd'],
    () => getTimeEnd(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className='ton-promo-sections-frens-info-timer d-flex flex-column justify-content-center'>
      <div className='ton-promo-sections-frens-info-timer-title'>
        {t("tonPromoSectionsFrensInfoTimer.title")}
      </div>
      <div className='ton-promo-sections-frens-info-timer-time text-center'>
        
        {isSuccess &&
          <MemoCountdown timeAwait={data.timeEnd}/>
        }

        {isLoading &&
          <div style={{ marginTop: '5px' }}>
            <DataFetchLoader width={21} height={21} color={'#B28E4C'} />
          </div>
        }

      </div>
    </div>
  )
}