//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

const NAME = "userApiErrorHash"

export const userApiErrorHashSlice = createSlice({
    name: NAME,
    initialState: {
        error: false,
    },
    reducers: {
        setUserApiErrorHash: (state, action) => {     
            state.error = action.payload     
        },
    },
})

export const { setUserApiErrorHash } = userApiErrorHashSlice.actions

export default userApiErrorHashSlice.reducer