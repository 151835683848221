//@ts-nocheck
import { useQuery } from 'react-query'
import { get } from '../../../api/exchangeAccess'
import './TonPromoSectionsExchange.scss'
import TonPromoSectionsExchangeAccess from './TonPromoSectionsExchangeAccess/TonPromoSectionsExchangeAccess'
import TonPromoSectionsExchangeConditions from './TonPromoSectionsExchangeConditions/TonPromoSectionsExchangeConditions'

export default function TonPromoSectionsExchange() {

  const { data, isLoading, isError, isSuccess } = useQuery(
    ['tonPromoExchangeAccess'],
    () => get(),
    {
        keepPreviousData: true,
    }
  )

  return (
    <div className='ton-promo-sections-exchange w-100 overflow-auto'>
        <TonPromoSectionsExchangeAccess data={data} isLoading={isLoading} isSuccess={isSuccess}/>
        <TonPromoSectionsExchangeConditions data={data} isLoading={isLoading} isSuccess={isSuccess}/>    
    </div>
  )
}