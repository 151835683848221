//@ts-nocheck

import { useMutation, useQueryClient } from 'react-query'
import './ShopCrystalsItem.scss'
import { useTranslation } from 'react-i18next'
import { buy } from '../../../api/shopCrystalStars'
import { useState } from 'react'

import { initInvoice } from '@telegram-apps/sdk';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader'
const invoice = initInvoice();

export default function ShopCrystalsItem({ item }) {
    
    const { t, i18n } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const queryClient = useQueryClient()
    const shopCrystalsItemBuyMutation = useMutation(
        (id: number) => buy(id),
        {
            onSuccess: (data) => {

                if (data.data.invoice.ok === true) {
                    invoice.open(data.data.invoice.result, 'url').then((status) => {
                        if (status === 'paid') { 
                            queryClient.invalidateQueries(['userLoot']).then(() => {
                                setIsLoading(false)
                            })                          
                        } else {
                            setIsLoading(false);    
                        }
                    })
                }

            },
        }
    )
    const shopCrystalsItemBuyOnClick = () => {
        if (isLoading) return

        setIsLoading(true)

        shopCrystalsItemBuyMutation.mutate(item.id)
    }

    return (
        <div onClick={shopCrystalsItemBuyOnClick} className='shop-crystals-item d-flex flex-column align-items-center justify-content-between position-relative'>
            <div className='w-100 d-flex flex-column align-items-center'>
                <img src={`/img/shop/crystal/${item.pathImage}.png`} height={80} width={'auto'}/>
                <div className='shop-crystals-item-name text-center'>
                    {i18n.language === 'en' &&
                        (item.nameEn)  
                    }
                    {i18n.language === 'ru' &&
                        (item.nameRu)   
                    }
                </div>
            </div>
            <div className='w-100 d-flex flex-column'>
                <div className='shop-crystals-item-price d-flex align-items-center justify-content-center'>
                    <img src='/img/loot/star-icon.png' width={16} style={{marginRight: '3px'}}/>  
                    <span>{item.price}</span> 
                </div>
                <div className='shop-crystals-item-count w-100 text-start'>
                    {t("shopCrystalsItem.count", {count: item.count})}
                </div>
            </div>
            {(isLoading) &&
                <div className='w-100 h-100 position-absolute top-0' style={{background: 'rgb(0,0,0,50%)', borderRadius: '4px'}}>
                    <DataFetchLoader height={50} width={50} color={"#B28E4C"}/>
                </div>
            }
        </div>
    )
}