import axios from "axios"
import { getAxiosConfig, getApiUrl } from "./index"

const axiosConfig = getAxiosConfig()
const apiURL = getApiUrl()

export async function getByUserId() {
    const { data } = await axios.get(
        apiURL + '/api/v1/user-comb', 
        axiosConfig
    )    
    return data
}