//@ts-nocheck

import { useEffect, useState } from 'react'
import './RatingContainer.scss'
import Countdown from 'react-countdown'
import RatingContainerItemGold from './RatingContainerItemGold/RatingContainerItemGold'
import RatingContainerItemGlobal from './RatingContainerItemGlobal/RatingContainerItemGlobal'
import RatingContainerItemFrens from './RatingContainerItemFrens/RatingContainerItemFrens'
import { useQuery } from 'react-query'
import { get } from '../../api/rating'
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader'
import DataFetchError from '../DataFetchError/DataFetchError'
import { numberFormat } from '../../utils/NumberFormat'
import { useTranslation } from 'react-i18next'

export default function RatingContainer() {

    const { t } = useTranslation()

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['rating'],
        () => get(),
        {
            keepPreviousData: true,
        }
    )

    const [isTimerComplete, setIsTimerComplete] = useState(true)
    const [activeNavbarItem, setActiveNavbarItem] = useState('')

    useEffect(() => {
        setActiveNavbarItem('gold')
    }, [])

    const timerComplete = () => {
        setIsTimerComplete(true)
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return;
        } else {
            return <span className='refferal-friends-container-reload-text'>{(seconds < 10 ? '0' : '') + seconds}</span>;
        }
    }

    const onClickGetRating = () => {
        if(isTimerComplete === false)
            return

        setIsTimerComplete(false)
        refetch()
    }

    return (
        <div className='rating-container w-100 h-100 d-flex flex-column'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='rating-container-title'>{t("ratingContainer.title")}</div>

                <div className='position-relative' onClick={onClickGetRating}>

                    {!isTimerComplete &&
                        <div className='position-absolute top-50 start-50 translate-middle'>
                            <Countdown onComplete={timerComplete} date={Date.now() + 10000} renderer={renderer}>
                            </Countdown>
                        </div>
                    }

                    <div className={!isTimerComplete ? `rating-container-reload-await` : ''} style={{ cursor: 'pointer' }}>
                        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0456 4.96994C12.8511 4.59864 12.6278 4.2639 12.4078 4.02633C12.6804 3.61594 12.8401 3.12437 12.8401 2.59371C12.8398 1.16145 11.6787 0.000356067 10.2465 0C8.81421 0.000356067 7.65312 1.16145 7.65312 2.59367C7.65312 3.12358 7.81284 3.6159 8.08546 4.02629C7.86506 4.26386 7.64212 4.5986 7.44771 4.9699C7.25254 5.34634 7.09065 5.74945 7.02961 6.12878C7.00951 6.25487 7.00001 6.37803 7.00001 6.49719C6.99855 6.89373 7.11297 7.25186 7.31319 7.54059C7.61395 7.97657 8.07846 8.25177 8.59124 8.42351C9.10579 8.59458 9.68033 8.66584 10.2464 8.66619C11.0015 8.66437 11.7707 8.54228 12.3939 8.21521C12.7049 8.05111 12.9804 7.8311 13.18 7.54059C13.3799 7.25186 13.4943 6.89405 13.4932 6.49719C13.4932 6.37763 13.4837 6.25487 13.4632 6.12878C13.4026 5.74945 13.2407 5.34638 13.0456 4.96994ZM8.91692 1.26416C9.25827 0.922807 9.72606 0.713044 10.2465 0.713044C10.7669 0.713044 11.2351 0.922846 11.5764 1.26416C11.917 1.60551 12.1275 2.07365 12.1275 2.59371C12.1271 3.11412 11.9174 3.58156 11.5764 3.92326C11.235 4.26386 10.7669 4.47398 10.2465 4.47398C9.72606 4.47366 9.25827 4.26386 8.91692 3.92326C8.57589 3.58191 8.36577 3.11412 8.36577 2.59371C8.36581 2.07365 8.57593 1.60551 8.91692 1.26416ZM12.5924 7.13788C12.4151 7.39773 12.0961 7.60788 11.6762 7.74746C11.2585 7.88708 10.7523 7.95359 10.2465 7.95283C9.57256 7.95465 8.89718 7.83296 8.43196 7.58446C8.19877 7.46166 8.02007 7.31108 7.9009 7.13708C7.78213 6.96237 7.71377 6.76286 7.7127 6.49719C7.7127 6.41791 7.71927 6.33162 7.73355 6.23952C7.77264 5.98477 7.90826 5.62625 8.07965 5.29954C8.23276 5.00424 8.41585 4.73308 8.56315 4.56387C9.0163 4.95159 9.60357 5.18698 10.2465 5.18698C10.8897 5.18698 11.477 4.95162 11.9302 4.56422C12.0771 4.73308 12.2605 5.00424 12.4137 5.29954C12.585 5.62625 12.7207 5.98477 12.7594 6.23952C12.774 6.33162 12.7806 6.41712 12.7806 6.49719C12.7795 6.7629 12.7112 6.96245 12.5924 7.13788Z" fill="#B0B0B0" />
                            <path d="M6.0459 14.6992C5.85114 14.3279 5.6282 13.9931 5.40744 13.7556C5.68082 13.3456 5.84053 12.8529 5.84053 12.3226C5.84014 10.89 4.67908 9.72927 3.24682 9.72891C1.81456 9.72931 0.653865 10.89 0.653469 12.3226C0.653469 12.8525 0.813185 13.3448 1.08621 13.7556C0.866199 13.9924 0.642154 14.3272 0.447742 14.6992C0.252578 15.0757 0.0910419 15.4784 0.0296401 15.8578C0.00989819 15.9842 7.4388e-06 16.107 7.4388e-06 16.2261C-0.00106076 16.6223 0.11296 16.9812 0.313228 17.2699C0.614025 17.7056 1.07885 17.9811 1.59127 18.1529C2.10622 18.3243 2.68032 18.3948 3.24682 18.3955C4.00188 18.3941 4.7715 18.2716 5.39466 17.9446C5.70491 17.7801 5.98118 17.5601 6.18002 17.2703C6.37993 16.9812 6.49467 16.623 6.4936 16.2269C6.4936 16.107 6.48371 15.9842 6.46361 15.8578C6.40256 15.4784 6.24067 15.0757 6.0459 14.6992ZM1.91727 10.9927C2.25897 10.6521 2.72677 10.4423 3.24682 10.4416C3.76723 10.4423 4.23503 10.6521 4.57638 10.9927C4.91777 11.3341 5.12713 11.8019 5.12749 12.3226C5.12713 12.8426 4.91773 13.3105 4.57638 13.6525C4.23503 13.9931 3.76723 14.2029 3.24682 14.2029C2.72677 14.2029 2.25862 13.9931 1.91687 13.6525C1.57624 13.3105 1.36647 12.8426 1.36647 12.3226C1.36651 11.8019 1.57628 11.3341 1.91727 10.9927ZM5.59275 16.8668C5.41547 17.1273 5.09608 17.3372 4.67651 17.4768C4.25916 17.6164 3.75224 17.6829 3.24682 17.6829C2.57291 17.6844 1.89717 17.5623 1.43231 17.3142C1.19881 17.1906 1.02042 17.0404 0.900936 16.8661C0.782168 16.6914 0.713803 16.4915 0.713091 16.2261C0.713091 16.1465 0.719658 16.0606 0.734296 15.9689C0.773068 15.7138 0.908294 15.3556 1.08004 15.0289C1.23315 14.7332 1.41664 14.4624 1.56389 14.2929C2.01673 14.681 2.604 14.9163 3.24686 14.9163C3.88972 14.9163 4.47664 14.681 4.92983 14.2936C5.07709 14.4621 5.26058 14.7333 5.41369 15.0289C5.58547 15.3557 5.7207 15.7138 5.75943 15.9689C5.77403 16.0603 5.78063 16.1465 5.78063 16.2269C5.77949 16.4922 5.71116 16.6921 5.59275 16.8668Z" fill="#B0B0B0" />
                            <path d="M20.2266 15.8578C20.1652 15.4784 20.0037 15.0757 19.8085 14.6992C19.6141 14.3272 19.3901 13.9924 19.1701 13.7556C19.4431 13.3448 19.6028 12.8525 19.6028 12.3226C19.6024 10.89 18.4417 9.72927 17.0094 9.72891C15.5772 9.72931 14.4161 10.89 14.4157 12.3226C14.4157 12.8525 14.5754 13.3448 14.8485 13.7556C14.6285 13.9924 14.4048 14.3279 14.21 14.6996C14.0156 15.0757 13.8537 15.4784 13.7923 15.8577C13.7726 15.9841 13.7627 16.107 13.7627 16.2269C13.7616 16.623 13.876 16.9812 14.0759 17.2703C14.377 17.7055 14.8412 17.9814 15.3539 18.1529C15.8688 18.3242 16.4434 18.3948 17.0095 18.3955C17.7645 18.394 18.5342 18.2716 19.1573 17.9445C19.4679 17.7801 19.7439 17.56 19.943 17.2699C20.1426 16.9812 20.2573 16.623 20.2563 16.2261C20.2562 16.107 20.246 15.9842 20.2266 15.8578ZM15.6799 10.9927C16.0212 10.6521 16.489 10.4423 17.0094 10.442C17.5295 10.4424 17.9972 10.6521 18.339 10.9927C18.68 11.3341 18.8898 11.8019 18.8898 12.3226C18.8898 12.8426 18.68 13.3105 18.339 13.6525C17.9976 13.9931 17.5295 14.2029 17.0094 14.2029C16.489 14.2029 16.0212 13.9931 15.6799 13.6525C15.3385 13.3105 15.1288 12.8426 15.1288 12.3226C15.1287 11.8019 15.3385 11.3341 15.6799 10.9927ZM19.3554 16.8668C19.1781 17.1273 18.859 17.3372 18.4391 17.4768C18.0218 17.6164 17.5149 17.6829 17.0094 17.6829C16.3351 17.6844 15.6594 17.5623 15.1949 17.3145C14.9617 17.191 14.7827 17.0404 14.6635 16.8668C14.5451 16.6918 14.4768 16.4922 14.4757 16.2269C14.4757 16.1469 14.4823 16.061 14.4965 15.9689C14.5356 15.7138 14.6708 15.3556 14.8426 15.0289C14.9954 14.7332 15.1788 14.4624 15.3262 14.2929C15.7793 14.681 16.3666 14.9163 17.0095 14.9163C17.6523 14.9163 18.2393 14.681 18.6924 14.2936C18.8397 14.4621 19.0232 14.7333 19.1763 15.0289C19.3481 15.3557 19.4833 15.7138 19.522 15.9689C19.5366 16.0606 19.5433 16.1465 19.5433 16.2262C19.5421 16.4922 19.4741 16.6918 19.3554 16.8668Z" fill="#B0B0B0" />
                            <path d="M4.0602 7.22004L3.28872 7.04644C3.45429 6.50992 3.72475 6.00708 4.08801 5.56995C4.27657 5.34266 4.49072 5.13289 4.72901 4.94576C4.96113 4.76337 5.21326 4.60184 5.48736 4.46772L5.17271 3.82779C4.85217 3.98529 4.55727 4.17393 4.28864 4.38515C4.01051 4.60409 3.76019 4.84927 3.53943 5.11422C3.10305 5.63942 2.78441 6.24497 2.59289 6.88965L1.70996 6.6912L2.59142 8.63038L4.0602 7.22004Z" fill="#B0B0B0" />
                            <path d="M11.1548 17.498C10.8387 17.5707 10.5138 17.6083 10.1878 17.6083C9.94663 17.6083 9.70467 17.5879 9.4642 17.5469C9.17326 17.4969 8.88461 17.4165 8.60355 17.3036C8.32981 17.1947 8.06375 17.0561 7.80976 16.8862L7.41357 17.4797C7.71034 17.6778 8.02063 17.8386 8.33856 17.9654C8.66673 18.097 9.00409 18.1913 9.34393 18.2494C9.62424 18.2976 9.90675 18.321 10.1878 18.321C10.5861 18.321 10.9816 18.2713 11.3668 18.1784L11.623 19L12.8615 17.2677L10.9063 16.7005L11.1548 17.498Z" fill="#B0B0B0" />
                            <path d="M16.0752 5.4603C16.4751 5.88058 16.7912 6.37978 16.9947 6.93125C17.0974 7.20792 17.172 7.49882 17.2147 7.79851C17.244 8.0006 17.259 8.20601 17.259 8.41471C17.259 8.50716 17.256 8.60034 17.2498 8.69461L17.9614 8.74067C17.9683 8.63139 17.972 8.52283 17.972 8.41467C17.972 8.17128 17.9541 7.93152 17.9208 7.69688C17.8704 7.34678 17.7834 7.00721 17.6635 6.68418C17.4205 6.02562 17.0401 5.4332 16.5588 4.93617L17.1651 4.27867L15.0454 4.07291L15.5318 6.04935L16.0752 5.4603Z" fill="#B0B0B0" />
                        </svg>
                    </div>

                </div>

            </div>

            <div className='rating-container-navbar d-flex justify-content-between'>
                <div onClick={() => setActiveNavbarItem('gold')} className={`rating-container-navbar-item ${activeNavbarItem === 'gold' ? 'rating-container-navbar-item-active' : ''} d-flex align-items-center`}>
                    {t("ratingContainer.coins")}
                </div>

                <div onClick={() => setActiveNavbarItem('frens')} className={`rating-container-navbar-item ${activeNavbarItem === 'frens' ? 'rating-container-navbar-item-active' : ''} d-flex align-items-center`}>
                {t("ratingContainer.friends")}
                </div>
            </div>

            {isSuccess &&
                <>
                    <div className='rating-container-items-container overflow-auto h-100'>

                        {activeNavbarItem === 'gold' &&
                            <>
                                {data.data.ratingGold.map((ratingGoldItem, index) => {
                                    return (
                                        <RatingContainerItemGold
                                            key={index}
                                            user={ratingGoldItem.user.telegramFirstName + (ratingGoldItem.user.telegramLastName === "" ? '' : ' ' + ratingGoldItem.user.telegramLastName)}
                                            telegramId={ratingGoldItem.user.telegramId}
                                            place={index + 1}
                                            count={numberFormat(ratingGoldItem.count)}
                                        />
                                    )
                                })
                                }
                            </>
                        }

                        {activeNavbarItem === 'frens' &&
                            <>
                                {data.data.ratingFrens.map((ratingFrensItem, index) => {
                                    return (
                                        <RatingContainerItemFrens
                                            key={index}
                                            user={ratingFrensItem.user.telegramFirstName + (ratingFrensItem.user.telegramLastName === "" ? '' : ' ' + ratingFrensItem.user.telegramLastName)}
                                            telegramId={ratingFrensItem.user.telegramId}
                                            place={index + 1}
                                            count={numberFormat(ratingFrensItem.count)}
                                        />
                                    )
                                })
                                }
                            </>
                        }
                    </div>

                    <div className='rating-container-user w-100'>
                        {activeNavbarItem === 'gold' &&

                            <div className='w-100 h-100 d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='rating-container-user-place'>
                                        {(data.data.ratingGoldUser.place + 1 >= 101 ? "100 +" : data.data.ratingGoldUser.place + 1)}
                                    </div>
                                    <div className='rating-container-user-name'>
                                        {data.data.ratingGoldUser.user.telegramFirstName + (data.data.ratingGoldUser.user.telegramLastName === "" ? '' : ' ' + data.data.ratingGoldUser.user.telegramLastName)}
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <div className='rating-container-user-count' style={{ marginRight: '6px' }}>{numberFormat(data.data.ratingGoldUser.count)}</div>
                                        <img src='/img/loot/gold-icon.png' width={20} height={22} />
                                    </div>
                                </div>
                            </div>

                        }

                        {activeNavbarItem === 'frens' &&

                            <div className='w-100 h-100 d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='rating-container-user-place'>
                                        {(data.data.ratingFrensUser.place + 1 >= 101 ? "100 +" : data.data.ratingFrensUser.place + 1)}
                                    </div>
                                    <div className='rating-container-user-name'>
                                        {data.data.ratingFrensUser.user.telegramFirstName + (data.data.ratingFrensUser.user.telegramLastName === "" ? '' : ' ' + data.data.ratingFrensUser.user.telegramLastName)}
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <div className='rating-container-user-count' style={{ marginRight: '6px' }}>{numberFormat(data.data.ratingFrensUser.count)}</div>
                                        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.71429 9C10.2013 9 12.2143 6.98705 12.2143 4.5C12.2143 2.01295 10.2013 0 7.71429 0C5.22723 0 3.21429 2.01295 3.21429 4.5C3.21429 6.98705 5.22723 9 7.71429 9ZM10.8 10.2857H10.4665C9.6308 10.6875 8.70268 10.9286 7.71429 10.9286C6.72589 10.9286 5.80179 10.6875 4.96205 10.2857H4.62857C2.07321 10.2857 0 12.3589 0 14.9143V16.0714C0 17.1362 0.863839 18 1.92857 18H13.5C14.5647 18 15.4286 17.1362 15.4286 16.0714V14.9143C15.4286 12.3589 13.3554 10.2857 10.8 10.2857ZM19.2857 9C21.4152 9 23.1429 7.27232 23.1429 5.14286C23.1429 3.01339 21.4152 1.28571 19.2857 1.28571C17.1562 1.28571 15.4286 3.01339 15.4286 5.14286C15.4286 7.27232 17.1562 9 19.2857 9ZM21.2143 10.2857H21.0616C20.5031 10.4786 19.9125 10.6071 19.2857 10.6071C18.6589 10.6071 18.0683 10.4786 17.5098 10.2857H17.3571C16.5375 10.2857 15.7821 10.5228 15.1192 10.9045C16.0996 11.9612 16.7143 13.3634 16.7143 14.9143V16.4571C16.7143 16.5455 16.6942 16.6299 16.6902 16.7143H23.7857C24.8504 16.7143 25.7143 15.8504 25.7143 14.7857C25.7143 12.2987 23.7013 10.2857 21.2143 10.2857Z" fill="#B0B0B0" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </>
            }

            {isLoading &&
                <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
            }

            {isError &&
                <DataFetchError text={t("ratingContainer.dataFetchError")} />
            }

        </div>
    )
}