//@ts-nocheck

import { createSlice } from '@reduxjs/toolkit'

const NAME = 'userQuestCount'

export const userQuestCountSlice = createSlice({
    name: NAME,
    initialState: {
        count: 0,
    },
    reducers: {
        setUserQuestCount: (state, action) => {
            state.count = action.payload
        },
        incrementUserQuestCount: (state, action) => {                    
            state.count++ 
        },
        decrementUserQuestCount: (state, action) => {         
            state.count--
        },
    },
})

export const { setUserQuestCount, incrementUserQuestCount, decrementUserQuestCount } = userQuestCountSlice.actions

export default userQuestCountSlice.reducer