//@ts-nocheck

import { useTranslation } from 'react-i18next';
import './ShopChests.scss'
import { useQuery } from 'react-query';
import { get } from '../../api/chest';
import ShopChest from './ShopChest/ShopChest';
import DataFetchLoader from '../DataFetchLoader/DataFetchLoader';
import DataFetchError from '../DataFetchError/DataFetchError';
import ShopSmallChest from './ShopSmallChest/ShopSmallChest';

export default function ShopChests() {

  const { t } = useTranslation()
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['chests'],
    () => get(),
    {
      keepPreviousData: true,
    }
  )


  return (
    <>
      <ShopSmallChest />
      <div className='d-flex justify-content-between gap-2 h-100'>
        {isSuccess ?
          data.data.chests.slice(1).map(item => {
            return (<ShopChest key={item.id} chest={item} />)
          }) : null
        }

        {(isLoading) &&
          <div className='w-100 h-100'>
            <DataFetchLoader width={80} height={80} color={"#B28E4C"} />
          </div>
        }

        {isError &&
          <DataFetchError text={t("shopHeroContainer.dataFetchError")} />
        }
      </div>
    </>
  )
}
