//@ts-nocheck

import './ShopChest.scss';
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, open } from "../../../api/chest";
import ToastInfo from "../../Toasts/ToastInfo/ToastInfo";
import { useSelector } from 'react-redux';
import ToastError from '../../Toasts/ToastError/ToastError';
import { useState } from 'react';
import DataFetchLoader from '../../DataFetchLoader/DataFetchLoader';
import ShopChestInfoModal from './ShopChestInfoModal/ShopChestInfoModal';
import ShopChestOpenOffcanvas from './ShopChestOpenOffcanvas/ShopChestOpenOffcanvas';

export default function ShopChest(chest) {
 
    const { t } = useTranslation()

    const chestId = chest.chest.id
    const chestNameRU = chest.chest.title
    const chestNameEN = chest.chest.titleEn
    const chestImg = chest.chest.pathImage
    const chestSale = chest.chest.sale
    const chestPrice = chest.chest.price
    const chestLoot = chest.chest.loot
    const chestHeroes = chest.chest.chestHeroes
    
    console.log(chestHeroes);
    

    const countUserLootCrystal = useSelector((state) => state.userLootCrystal.count)
    const canBy = chestSale > 0 ? BigInt(countUserLootCrystal) >= BigInt((chestPrice - (chestPrice * (chestSale / 100))).toString()) : BigInt(countUserLootCrystal) >= BigInt(chestPrice)

    const [isLoading, setIsLoading] = useState(false)
    const [randomList, setRandomList] = useState([]);
    const [prize, setPrize] = useState();

    const generateRandomList = () => {
        const randomListNew = [];
        for (let i = 0; i < 50; i++) {            
            randomListNew.push(chestHeroes[Math.floor(Math.random() * chestHeroes.length)]);
        }
        setRandomList(randomListNew);
    }

    const queryClient = useQueryClient()
    const shopChestBuyMutation = useMutation(
        (chestId: number) => open(chestId),
        {
            onSuccess: (data) => {

                if (!data.data.ok) {
                    if (data.data.errorCode === "NotEnoughCrystals") {
                        ToastError("Not enough crystals!", true)
                    }

                    if (data.data.errorCode === "ChestNotFoundOrEmpty") {
                        ToastError("Chest not found or empty!", true)
                    }

                    setIsLoading(false)
                    return
                }

                queryClient.invalidateQueries(['userLoot']).then(() => {

                    setIsLoading(false)

                    const offcanvas = document.getElementById(`chestOpenOffcanvas${chestId}`)
                    const bsOffcanvas = new bootstrap.Offcanvas(offcanvas)
                    bsOffcanvas.show()
                    setPrize(data.data.chestRewards)
                })
            },
        }
    );

    const shopChestBuyOnClick = () => {

        if (!canBy) {
            const shopHeroPriceElem = document.getElementById(
                "shopChestPriceDetails" + chest.chest.id
            );
            shopHeroPriceElem.classList.toggle("chest-price-animate")
            setTimeout(() => {
                shopHeroPriceElem.classList.remove("chest-price-animate")
            }, 300)

            return
        }

        if (isLoading) return
        setIsLoading(true)

        shopChestBuyMutation.mutate(chestId)

        generateRandomList()
    }

    return (
        <>
            <div id='shop-chest' className='shop-chest-container position-relative'>
                <div className='position-absolute info-logo' data-bs-toggle="modal" data-bs-target={"#chestModal" + chestId}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse opacity="0.7" cx="8.2227" cy="8.20513" rx="8.2227" ry="8.20513" fill="#B28E4C" />
                        <path
                            d="M7.76705 12V6.54545H8.60511V12H7.76705ZM8.19318 5.63636C8.02983 5.63636 7.88897 5.58073 7.7706 5.46946C7.65459 5.35819 7.59659 5.22443 7.59659 5.06818C7.59659 4.91193 7.65459 4.77817 7.7706 4.6669C7.88897 4.55563 8.02983 4.5 8.19318 4.5C8.35653 4.5 8.49621 4.55563 8.61222 4.6669C8.73059 4.77817 8.78977 4.91193 8.78977 5.06818C8.78977 5.22443 8.73059 5.35819 8.61222 5.46946C8.49621 5.58073 8.35653 5.63636 8.19318 5.63636Z"
                            fill="white" />
                    </svg>
                </div>
                <div className='shop-chest-name w-100 text-center text-white lh-1'>
                    {localStorage.getItem('i18nLanguage') === 'en' ? chestNameEN : chestNameRU}
                </div>

                <div className='shop-chest-img d-flex align-items-center justify-content-center'>
                    <img src={`/img/chests/x2/${chestImg}.png`} width='90px' />
                </div>

                <div className='chest-price-container position-absolute bottom-0 w-100 d-flex flex-column justify-content-center'>
                    {chestSale > 0 ?
                        <div className='d-flex align-items-center justify-content-center lh-1'>
                            <div className='chest-old-price text-decoration-line-through'>{chestPrice}</div>
                            <div className='chest-sale'>-{chestSale}%</div>
                        </div> :
                        <div id={"shopChestPriceDetails" + chest.chest.id} className={`text-white lh-1 chest-price ${!canBy ? "chest-price-red" : ''}`}>
                            {chestPrice}
                        </div>
                    }

                    <div className='d-flex gap-1 align-items-end justify-content-center'>
                        <div>
                            <img src={`/img/loot/${chestLoot.pathImage}-icon.png`} height='16px' />
                        </div>
                        <div id={"shopChestPriceDetails" + chest.chest.id} className={`text-white lh-1 chest-price ${!canBy ? "chest-price-red" : ''}`}>
                            {chestSale > 0 ? chestPrice - (chestPrice * (chestSale / 100)) : chestPrice - (chestPrice * (chestSale / 100))}
                        </div>
                    </div>
                    <div className='position-relative d-flex justify-content-center chest-buy-btn' type='button'>
                        <svg width="82" height="26" viewBox="0 0 82 26" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M81.211 12.9115L69.8076 24.821H18.0721L4.98145 12.9115L18.0721 1H69.8076L81.211 12.9115Z"
                                stroke="#B28E4C" strokeMiterlimit="10" />
                            <path
                                d="M77.2296 13.0905L65.8261 25H14.0906L1 13.0905L14.0906 1.17896H65.8261L77.2296 13.0905Z"
                                stroke="#B28E4C" strokeMiterlimit="10" />
                        </svg>
                        <div className='shop-buy-text position-absolute w-100 h-100 d-flex justify-content-center align-items-center' onClick={shopChestBuyOnClick}>
                            {!isLoading &&
                                (t("shop.buy"))
                            }
                            {isLoading &&
                                <DataFetchLoader width={15} height={15} color={"#B28E4C"} />
                            }
                        </div>
                    </div>
                    <ShopChestOpenOffcanvas chest={chest.chest} randomList={randomList} prize={prize} />
                </div>
            </div>
            <ShopChestInfoModal
                chestname={localStorage.getItem('i18nLanguage') === 'en' ? chestNameEN.toLowerCase() : chestNameRU.toLowerCase()}
                chestHeroes={chestHeroes}
                id={chestId}
            />
        </>
    )
}